import {
  BaseQueryFn as RtkBaseQueryFn,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { RootState } from 'src/store/';
import { isEqual, joinValues, stringifyQuery } from 'src/utils';
import { getAccessToken } from 'src/entities/auth/helpers';
import { AppConfig } from 'src/entities/config';
import { API_DEFAULT_BASE_URL, API_ERROR_EVENT } from './constants';
import {
  FetchArgs,
  QueryFn,
  QueryResult,
  UseQueryResult,
  UseQueryStateResult,
} from './types';

type BaseQueryFn<T = unknown> = RtkBaseQueryFn<
  FetchArgs,
  T,
  FetchBaseQueryError
>;

type BaseQueryParams = {
  baseUrl?: string;
  path?: string;
};

const getApiReqParams = ({ url: reqUrl, queryParams, ...rest }: FetchArgs) => {
  const queryString = queryParams ? stringifyQuery(queryParams) : '';
  const url = (reqUrl ?? '') + queryString;
  return { url, ...rest };
};

export const baseQuery = (params?: BaseQueryParams): BaseQueryFn => {
  const { baseUrl = API_DEFAULT_BASE_URL, path } = params || {};
  const query = fetchBaseQuery({
    baseUrl: joinValues([baseUrl, path]),
  }) as BaseQueryFn;

  return async (args, api, extraOptions) => {
    const accessToken = await getAccessToken();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      ...args.headers,
    };
    const { config } = api.getState() as RootState;
    const result = await query(
      getApiReqParams({ ...args, headers }),
      api,
      extraOptions,
    );
    if (result.error) {
      document.dispatchEvent(
        new CustomEvent(API_ERROR_EVENT, { detail: result.error }),
      );
    }
    if (config) {
      result.meta = { ...result.meta, ...config };
    }

    return result;
  };
};

export const isLoadingWithNewParams = <R, P>(
  { originalArgs, isFetching, isLoading }: Partial<UseQueryResult<R>>,
  params: P,
) => (isEqual(originalArgs, params) ? isLoading : isFetching);

export const queryWithConfig =
  <R, P>(queryFn: (params: P, config: AppConfig) => FetchArgs): QueryFn<P, R> =>
  (params, api, extra, baseQuery) => {
    const { config } = api.getState() as RootState;
    return baseQuery(queryFn(params, config)) as QueryResult<R>;
  };

export const selectFromQueryResult =
  <R, S, P>(selector: (data?: R, params?: P) => S, params?: P) =>
  ({ data, ...rest }: UseQueryStateResult<R>) =>
    ({ data: selector(data, params), ...rest }) as UseQueryStateResult<S>;
