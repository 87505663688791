import Box, { BoxProps } from '@mui/material/Box';
import logo from 'src/assets/logo.png';

function AppLogo(props: BoxProps) {
  return (
    <Box component="img" src={logo} color="inherit" width="20rem" {...props} />
  );
}

export default AppLogo;
