import { configureStore } from '@reduxjs/toolkit';
import { configApi, configSlice } from 'src/entities/config';
import { devicesApi } from 'src/entities/devices';
import { dataApi } from 'src/entities/data';
import { firmwareApi } from 'src/entities/firmware';
import { sitesApi } from 'src/entities/sites';
import { backendApi } from 'src/backend';
import userProfileReducer from 'src/components/UserStore/userProfileSlice';
import storage from 'redux-persist/es/storage';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
  persistStore,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedUserProfileReducer = persistReducer(
  persistConfig,
  userProfileReducer.reducer,
);

export const store = configureStore({
  devTools: import.meta.env.MODE !== 'production',
  reducer: {
    [backendApi.reducerPath]: backendApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [configSlice.name]: configSlice.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
    [devicesApi.reducerPath]: devicesApi.reducer,
    [firmwareApi.reducerPath]: firmwareApi.reducer,
    [sitesApi.reducerPath]: sitesApi.reducer,
    userProfile: persistedUserProfileReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    backendApi.middleware,
    configApi.middleware,
    dataApi.middleware,
    devicesApi.middleware,
    firmwareApi.middleware,
    sitesApi.middleware,
  ],
});

export const persistor = persistStore(store);
