export enum ContractGroup {
  Property = 'properties',
  Telemetry = 'telemetry',
  Event = 'events',
}

export enum ContractRowFormat {
  DateTime = 'dateTime',
  Default = 'default',
}

export enum ContractRowField {
  Name = 'name',
  Path = 'path',
}

export type ContractRow<T extends string | number = string> = {
  name: string;
  path: string;
  label: string;
  helperText?: string;
  group?: string;
  defaultValue?: T;
  values?: Record<T, string>;
  format?: ContractRowFormat;
  flex?: number;
};

export type ContractGroups = Record<ContractGroup, ContractRow[]>;

export type AppConfig = {
  contract: Record<string, ContractGroups>;
  blueprint: Record<string, unknown>;
};
