import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function AppVersionBanner() {
  const showVersionBanner =
    import.meta.env.REACT_APP_MODE.toLowerCase() === 'dev';

  if (!showVersionBanner) {
    return null;
  }
  return (
    <Stack direction="row" gap={1}>
      <Typography>DEV</Typography>
      <Typography>{import.meta.env.REACT_APP_NAME}</Typography>
      <Typography variant="body2">
        v.{import.meta.env.REACT_APP_VERSION}
      </Typography>
    </Stack>
  );
}

export default AppVersionBanner;
