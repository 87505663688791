import Typography from '@mui/material/Typography';
import { useMatchRouteMeta } from 'src/router';
import { PortalRole } from 'src/backend';
import StatusInfo from './StatusInfo';
import RoleBased from './RoleBased';

function PageTitle() {
  const { title } = useMatchRouteMeta(true);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '10px',
        justifyContent: 'space-between',
      }}
    >
      <Typography marginY={2} variant="h1">
        {title}
      </Typography>
      <RoleBased
        requiredRole={PortalRole[PortalRole.Read]}
        component={<StatusInfo />}
      />
    </div>
  );
}

export default PageTitle;
