import { scopes, msalInstance } from './config';

export const getAccessToken = () => {
  const [account] = msalInstance.getAllAccounts();
  return msalInstance
    .acquireTokenSilent({
      account,
      scopes,
    })
    .then(({ accessToken }) => accessToken)
    .catch(() => msalInstance.logoutRedirect());
};

export const getUsername = () => {
  const [account] = msalInstance.getAllAccounts();
  return account.name?.split(' ') ?? [];
};

export const getUserId = () => {
  const [account] = msalInstance.getAllAccounts();
  return account.username;
};
