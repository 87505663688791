import { baseTheme } from '../baseTheme';

const { spacing } = baseTheme;

export default {
  defaultProps: {
    color: 'inherit',
  },
  styleOverrides: {
    fontSizeSmall: {
      fontSize: spacing(2),
    },
    fontSizeMedium: {
      fontSize: spacing(2.5),
    },
    fontSizeLarge: {
      fontSize: spacing(3),
    },
  },
};
