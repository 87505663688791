import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserProfile } from 'src/components/UserStore/userProfileSlice';
import { useLazyGetUserProfileQuery } from 'src/backend';
import AppLoader from './AppLoader';

type Props = {
  children: ReactNode;
};

function UserRolesProvider({ children }: Props) {
  const dispatchEvent = useDispatch();
  const [getUserProfile] = useLazyGetUserProfileQuery();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getUserProfile({})
      .unwrap()
      .then((data) => {
        dispatchEvent(setUserProfile(data?.data));
      })
      .finally(() => setIsLoading(false));
  }, [dispatchEvent, getUserProfile]);

  return isLoading ? <AppLoader /> : children;
}

export default UserRolesProvider;
