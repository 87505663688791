import { FormLabel } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  text: string;
  margin?: string;
  children: React.ReactNode;
  backgroundColor?: string;
  width?: string;
};

function LabeledBox({ text, margin, children, backgroundColor, width }: Props) {
  return (
    <Box
      sx={{
        '& .MuiTextField-root': { margin: 1 },
        border: 1,
        borderRadius: 1,
        padding: '3px',
        display: 'inline-block',
        margin,
        width,
      }}
    >
      <FormLabel
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.25em',
          width: 'fit-content',
          height: '2em',
          margin: '-1.3em 1em 0em 0.7em',
          padding: '4px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '1em',
          fontWeight: '600',
          backgroundColor: backgroundColor ?? '#f3f4f6',
        }}
      >
        {text}
      </FormLabel>
      {children}
    </Box>
  );
}

export default LabeledBox;
