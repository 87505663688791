type KustoQlWhereParam = string | number | undefined;

export type KustoQlQueryParams = {
  id: string;
  where: Record<string, KustoQlWhereParam | KustoQlWhereParam[]>;
  days?: number;
  limit?: number;
  telemetry?: boolean;
};

export const getKustoQlQuery = ({
  id,
  where,
  days = 7,
  limit = 100,
}: KustoQlQueryParams) => {
  const whereSingleQuery = (key: string, value: KustoQlWhereParam) =>
    value && `\n    | where ${key} == "${value}"`;
  const whereMultipleQuery = (key: string, value: KustoQlWhereParam[]) =>
    `\n    | where ${key} in("${value.join('","')}")`;

  const whereQuery = Object.entries(where)
    .map(([key, value]) =>
      Array.isArray(value)
        ? whereMultipleQuery(key, value)
        : whereSingleQuery(key, value),
    )
    .join('');

  return `${id}
    | where enqueuedTime > ago(${days}d) and enqueuedTime <= now()${whereQuery}
    | sort by enqueuedTime desc
    | limit ${limit}`;
};
