import { EChartsOption } from 'echarts-for-react';
import { baseTheme } from 'src/theme/baseTheme';
import { TelemetryChartPoints, TelemetrySelection } from './types';

const { palette } = baseTheme;
const chartColors = [
  palette.primary.main,
  palette.secondary.main,
  palette.info.main,
];

export const chartsIntialConfig: EChartsOption = {
  xAxis: { type: 'time', boundaryGap: false },
  yAxis: { type: 'value', boundaryGap: [0, '100%'] },
  dataZoom: [
    { type: 'inside', start: 40, end: 100 },
    { start: 0, end: 10 },
  ],
  tooltip: {
    trigger: 'axis',
    position: (pt: string[]) => [pt[0], '20%'],
  },
};

export const getChartsSeries = (points?: TelemetryChartPoints) =>
  Object.entries(points || {}).map(([name, data], index) => ({
    color: chartColors[index],
    type: 'line',
    data,
    name,
  }));

export const getChartsConfig = (
  points: TelemetryChartPoints,
  selected?: TelemetrySelection,
) => ({
  ...chartsIntialConfig,
  legend: { ...Object.keys(points), selected },
  series: getChartsSeries(points),
});
