import { PlayArrowOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetDeviceDetailsQuery } from 'src/backend';
import DataField from 'src/components/DataField/DataField';
import PageCard from 'src/components/PageCard';

type Params = {
  id: string;
};

export default function DeviceDetailsShotData() {
  const { id } = useParams<Params>();
  const { data } = useGetDeviceDetailsQuery(id ?? '');
  const lastShot = data?.lastShot;
  const lastUpdated = lastShot?.lastUpdated
    ? moment(lastShot.lastUpdated).format('LLLL')
    : '-';

  return (
    <PageCard title="Last shot data" icon={<PlayArrowOutlined />}>
      <Typography variant="h6">Last updated</Typography>
      <Typography variant="body2" sx={{ paddingBottom: '2em' }}>
        {lastUpdated}
      </Typography>
      <Grid container spacing={20}>
        <Grid item xs={6}>
          <DataField
            label="Hose Temperature A (F)"
            value={lastShot?.hoseTempAInF}
          />
          <DataField
            label="Hose Temperature B (F)"
            value={lastShot?.hoseTempBInF}
          />
          <DataField
            label="Manifold Temperature A (F)"
            value={lastShot?.manifoldTempAInF}
          />
          <DataField
            label="Manifold Temperature B (F)"
            value={lastShot?.manifoldTempBInF}
          />
          <DataField
            label="Dispenser Temperature (F)"
            value={lastShot?.dispenserTempInF}
          />
          <DataField
            label="Pump Pressure A (psi)"
            value={lastShot?.pumpPressureAInPsi}
          />
          <DataField
            label="Pump Pressure B (psi)"
            value={lastShot?.pumpPressureBInPsi}
          />
        </Grid>
        <Grid item xs={6}>
          <DataField
            label="Dispensed A (lbs)"
            value={lastShot?.dispensedAInLbs}
          />
          <DataField
            label="Dispensed B (lbs)"
            value={lastShot?.dispensedBInLbs}
          />
          <DataField
            label="Dispense Time (sec)"
            value={lastShot?.dispenseTimeInSec}
          />
          <DataField
            label="Solvent Pressure (psi)"
            value={lastShot?.solventPressureInPsi}
          />
          <DataField label="Solvent Flow" value={lastShot?.solventFlow} />
          <DataField
            label="Lifetime shot count"
            value={lastShot?.lifetimeShotCount}
          />
          <DataField
            label="Shots since reset"
            value={lastShot?.shotsSinceReset}
          />
        </Grid>
      </Grid>
    </PageCard>
  );
}
