import { RouteProps } from 'src/router/types';
import { Navigate } from 'react-router-dom';
import DeviceDetailsConfiguration from './DeviceDetailsConfiguration';
import DeviceDetailsTickets from './DeviceDetailsTickets';
import DeviceDetailsShotData from './DeviceDetailsShotData';
import DeviceDetailsAnalysis from './DeviceDetailsAnalysis';
import DeviceDetailsCustomAnalysis from './DeviceDetailsCustomAnalysis';

const ROUTE_CONFIGURATION = 'configuration';
const ROUTE_SHOTDATA = 'shot-data';
const ROUTE_TICKETS = 'tickets';
const ROUTE_ANALYSIS = 'analysis';
const ROUTE_CUSTOM_ANALYSIS = 'custom-analysis';

export const routes: RouteProps[] = [
  {
    index: true,
    element: <Navigate to={ROUTE_CONFIGURATION} replace />,
  },
  {
    path: ROUTE_CONFIGURATION,
    element: <DeviceDetailsConfiguration />,
    meta: {
      title: 'Configuration',
    },
  },
  {
    path: ROUTE_SHOTDATA,
    element: <DeviceDetailsShotData />,
    meta: {
      title: 'Last shot data',
    },
  },
  {
    path: ROUTE_TICKETS,
    element: <DeviceDetailsTickets />,
    meta: {
      title: 'Ticket history',
    },
  },
  {
    path: ROUTE_ANALYSIS,
    element: <DeviceDetailsAnalysis />,
    meta: {
      title: 'Analysis',
    },
  },
  {
    path: ROUTE_CUSTOM_ANALYSIS,
    element: <DeviceDetailsCustomAnalysis />,
    meta: {
      title: 'Custom Analysis',
    },
  },
];
