import React, { ReactNode } from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/system';

type Props = {
  title: string;
  subheader?: string;
  icon: ReactNode;
  action?: ReactNode;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
};

function PageCard({
  title,
  subheader,
  icon,
  action,
  children,
  sx,
  contentSx,
}: Props) {
  return (
    <Card sx={sx}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>{icon}</Avatar>}
        title={title}
        subheader={subheader}
        action={action}
      />
      {children && <CardContent sx={contentSx}>{children}</CardContent>}
    </Card>
  );
}

export default PageCard;
