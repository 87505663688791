import {
  FiberManualRecordRounded,
  PlayArrowOutlined,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { RealTimeConfig } from 'src/backend';
import DataField from 'src/components/DataField/DataField';
import PageCard from 'src/components/PageCard';
import { parseBool } from './utils';

type Params = {
  realTime: RealTimeConfig | undefined;
  xs: number | undefined;
};

function redOrGreen(input?: boolean) {
  if (input === undefined) {
    return '#D5D5D5';
  }

  return input ? 'rgba(201, 231, 168)' : 'rgba(231, 168, 168)';
}

function reverseLogic(input?: boolean) {
  if (input === undefined) {
    return undefined;
  }

  return !input;
}

export function DeviceDetailsConfigurationRealTime({
  realTime,
  xs = 4,
}: Params) {
  return (
    <Grid item xs={xs}>
      <PageCard title="Real Time Data" icon={<PlayArrowOutlined />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DataField
              label="Dispenser Home"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.dispenserHome)) }}
                />
              }
            />
            <DataField
              label="Dispenser Home 2"
              value={
                <FiberManualRecordRounded
                  sx={{
                    color: redOrGreen(
                      reverseLogic(parseBool(realTime?.dispenserHome2)),
                    ),
                  }}
                />
              }
            />
            <DataField
              label="Spindle"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.spindle)) }}
                />
              }
            />
            <DataField
              label="Film drive fault"
              value={
                <FiberManualRecordRounded
                  sx={{
                    color: redOrGreen(
                      reverseLogic(parseBool(realTime?.filmDriveFault)),
                    ),
                  }}
                />
              }
            />
            <DataField
              label="Left photo eye"
              value={
                <FiberManualRecordRounded
                  sx={{
                    color: redOrGreen(
                      reverseLogic(parseBool(realTime?.leftPhotoEye)),
                    ),
                  }}
                />
              }
            />
            <DataField
              label="Right photo eye"
              value={
                <FiberManualRecordRounded
                  sx={{
                    color: redOrGreen(
                      reverseLogic(parseBool(realTime?.rightPhotoEye)),
                    ),
                  }}
                />
              }
            />
            <DataField
              label="A OTP"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.aOtp)) }}
                />
              }
            />
            <DataField
              label="B OTP"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.bOtp)) }}
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DataField
              label="Top cover"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.topCover)) }}
                />
              }
            />
            <DataField
              label="Flip door"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.flipDoor)) }}
                />
              }
            />
            <DataField
              label="Jaw drive fault"
              value={
                <FiberManualRecordRounded
                  sx={{
                    color: redOrGreen(
                      reverseLogic(parseBool(realTime?.jawDriveFault)),
                    ),
                  }}
                />
              }
            />
            <DataField
              label="Dispenser drive fault"
              value={
                <FiberManualRecordRounded
                  sx={{
                    color: redOrGreen(
                      reverseLogic(parseBool(realTime?.dispenserDriveFault)),
                    ),
                  }}
                />
              }
            />
            <DataField
              label="Dispenser photo eye"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.spindle)) }}
                />
              }
            />
            <DataField
              label="Solvent level"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.spindle)) }}
                />
              }
            />
            <DataField
              label="Jaw"
              value={
                <FiberManualRecordRounded
                  sx={{ color: redOrGreen(parseBool(realTime?.spindle)) }}
                />
              }
            />
          </Grid>
        </Grid>
      </PageCard>
    </Grid>
  );
}
