import { Configuration, PublicClientApplication } from '@azure/msal-browser';

// export const b2cPolicies = {
//   names: {
//     signUpSignIn: import.meta.env.REACT_APP_SIGN_UP_SIGN_IN,
//   },
//   authorities: {
//     signUpSignIn: {
//       authority: import.meta.env.REACT_APP_AUTHORITY,
//     },
//   },
//   authorityDomain: import.meta.env.REACT_APP_AUTHORITY_DOMAIN,
// };

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: import.meta.env.REACT_APP_CLIENT_ID,
    authority: `${import.meta.env.REACT_APP_AUTHORITY}/${
      import.meta.env.REACT_APP_TENANT_ID
    }`,
    // knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin,
  },
} as Configuration);

export const scopes = [
  `${import.meta.env.REACT_APP_API_SCOPES_URL}/devices.read`,
  `${import.meta.env.REACT_APP_API_SCOPES_URL}/devices.write`,
];
