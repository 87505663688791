import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from 'src/entities/auth';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import store from 'src/store';
import theme from 'src/theme';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from 'src/store/store';
import AlertsProvider from './AlertsProvider';

type Props = {
  children: ReactNode;
};

function AppProviders({ children }: Props) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AlertsProvider>
            <AuthProvider>
              <BrowserRouter>{children}</BrowserRouter>
            </AuthProvider>
          </AlertsProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppProviders;
