import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

type Props = IconButtonProps & {
  loading?: boolean;
  loaderSize?: string;
};

function LoadingIconButton({
  loaderSize = '2.0rem',
  loading,
  disabled,
  children,
  ...props
}: Props) {
  return (
    <IconButton
      data-testid="loading-icon-btn"
      disabled={loading || disabled}
      {...props}
    >
      {loading ? <CircularProgress size={loaderSize} /> : children}
    </IconButton>
  );
}

export default LoadingIconButton;
