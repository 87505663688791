import { Typography } from '@mui/material';

function Unauthorized() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '10px',
        justifyContent: 'space-between',
      }}
    >
      <Typography marginY={2} variant="h1">
        Unauthorized
      </Typography>
    </div>
  );
}

export default Unauthorized;
