import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ReqMethod } from 'src/helpers';
import { DeviceType } from '../devices';
import { FIRMWARE_GET_LIST_API, FIRMWARE_STORE_API_KEY } from './constants';
import { FirmWareVersion } from './types';

export const firmwareApi = createApi({
  reducerPath: FIRMWARE_STORE_API_KEY,
  baseQuery: baseQuery(),
  endpoints: ({ query }) => ({
    getFirmwareList: query<FirmWareVersion[], DeviceType | undefined>({
      query: (deviceType) => ({
        url: FIRMWARE_GET_LIST_API,
        method: ReqMethod.Get,
        queryParams: { deviceType },
      }),
    }),
  }),
});

export const { useGetFirmwareListQuery } = firmwareApi;
