import { useEffect, useState } from 'react';
import { DASHBOARD, RoutableTabsLayout } from 'src/router';
import PageTitle from 'src/components/PageTitle';
import { useGetDeviceDetailsQuery } from 'src/backend';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { generatePath } from 'src/utils';
import { routes } from './routes';

type Params = {
  id: string | undefined;
};

function DeviceDetails() {
  const { id } = useParams<Params>();
  const { isLoading, refetch, error } = useGetDeviceDetailsQuery(id ?? '');
  const [refetchRequired, setRefetchRequired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      navigate(generatePath(DASHBOARD));
    }
  }, [error, navigate]);

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
    }
  }, [refetchRequired, refetch]);

  return (
    <>
      <PageTitle />
      {isLoading ? (
        <Loader />
      ) : (
        <RoutableTabsLayout
          routes={routes}
          refreshData={() => setRefetchRequired(true)}
        />
      )}
    </>
  );
}

export default DeviceDetails;
