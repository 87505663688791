import { ComputerOutlined } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { SystemConfig } from 'src/backend';
import DataField from 'src/components/DataField/DataField';
import PageCard from 'src/components/PageCard';

type Params = {
  system: SystemConfig | undefined;
  xs?: number | undefined;
};

export default function DeviceDetailsConfigurationSystem({
  system,
  xs = 4,
}: Params) {
  return (
    <Grid item xs={xs}>
      <PageCard title="System" icon={<ComputerOutlined />}>
        <DataField label="Display version" value={system?.displayVersion} />
        <DataField label="Display CPU ver." value={system?.displayCpuVersion} />
        <DataField label="Main CPU ver." value={system?.mainCpuVersion} />
        <DataField label="Cut CPU ver." value={system?.cutCpuVersion} />
      </PageCard>
    </Grid>
  );
}
