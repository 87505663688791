import { SensorsOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { SensorsConfig } from 'src/backend';
import DataField from 'src/components/DataField/DataField';
import PageCard from 'src/components/PageCard';
import OnOff from 'src/components/OnOff';
import { parseBool } from './utils';

type Params = {
  sensors: SensorsConfig | undefined;
  xs?: number | undefined;
};

export default function DeviceDetailsConfigurationSensors({
  sensors,
  xs = 4,
}: Params) {
  return (
    <Grid item xs={xs}>
      <PageCard title="Sensors" icon={<SensorsOutlined />}>
        <DataField
          value={<OnOff value={parseBool(sensors?.dispancerPhotoEye)} />}
          label="Dispancer photo eye"
        />
        <DataField
          value={<OnOff value={parseBool(sensors?.mainCoverSwitch)} />}
          label="Main cover switch"
        />
        <DataField
          value={<OnOff value={parseBool(sensors?.jawPhotoEyes)} />}
          label="Jaw photo eyes"
        />
        <DataField
          value={<OnOff value={parseBool(sensors?.filmSpindleSwitch)} />}
          label="Film spindle switch"
        />
        <DataField
          value={<OnOff value={parseBool(sensors?.flipDoorSwitch)} />}
          label="Flip door switch"
        />
        <DataField
          value={<OnOff value={parseBool(sensors?.filmLevelSense)} />}
          label="Film level sense"
        />
        <DataField
          value={<OnOff value={parseBool(sensors?.filmFeedAtStartup)} />}
          label="Film feed at startup"
        />
      </PageCard>
    </Grid>
  );
}
