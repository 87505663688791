import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { UserProfile } from 'src/backend';
import { Navigate, generatePath } from 'react-router-dom';
import { UNAUTHORIZED } from 'src/router';
import { hasRole } from 'src/backend/helpers';
import { getUserProfile } from './UserStore/userProfileSlice';

type Props = {
  requiredRole?: string;
  component: ReactNode;
  shouldRedirect?: boolean;
};

function RoleBased({ requiredRole, component, shouldRedirect }: Props) {
  const userProfile = useSelector(getUserProfile) as UserProfile;
  if (!requiredRole) {
    return component;
  }

  const isAuthorized = hasRole(userProfile, requiredRole);
  if (!isAuthorized && shouldRedirect) {
    return <Navigate to={generatePath(UNAUTHORIZED)} />;
  }
  return isAuthorized ? component : '';
}

export default RoleBased;
