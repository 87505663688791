export type DeviceEntry = {
  id: string;
  serialNumber: string;
  customerName: string;
  city: string;
  country: string;
  technician: string;
  isConnected: boolean;
  lastShotTimestamp: string;
  alarmStatus: string;
};

export type CustomerAddress = {
  street: string;
  houseNumber: string;
  city: string;
  regionCode: string;
  postCode: string;
  country: string;
};

export type DeviceOverview = {
  twinId: string;
  serialNumber: string;
  isConnected: boolean;
  customerName: string;
  assignedTechnician: string;
  customerId: string;
  customerAddress: CustomerAddress;
};

export type SensorsConfig = {
  dispancerPhotoEye: string;
  mainCoverSwitch: string;
  jawPhotoEyes: string;
  filmSpindleSwitch: string;
  flipDoorSwitch: string;
  filmLevelSense: string;
  filmFeedAtStartup: string;
};

export type ParamsConfig = {
  sealTempCut: number;
  sealTempTop: number;
  sealTempBottom: number;
  sealTempEdge: number;

  filmTensionIdle: number;
  filmTensionAdvance: number;
  filmTensionStop: number;

  ventLength: number;
  ventPosition: number;

  chemTemp: number;

  foamFlowRate: number;
  foamRatioAb: number;

  foamFillFactor: number;
  stopPosition: number;

  bagSequenceDelayFilmDeploy: number;
  bagSequenceDelayFoamDrip: number;
  bagSequenceDelayBagDelay: number;
};

export type RealTimeConfig = {
  dispenserHome: string;
  dispenserHome2: string;
  spindle: string;
  filmDriveFault: string;
  leftPhotoEye: string;
  rightPhotoEye: string;
  aOtp: string;
  bOtp: string;
  topCover: string;
  flipDoor: string;
  jawDriveFault: string;
  dispenserDriveFault: string;
  dispenserPhotoEye: string;
  solventLevel: string;
  jaw: string;
};

export type SystemConfig = {
  displayVersion: string;
  displayCpuVersion: string;
  mainCpuVersion: string;
  cutCpuVersion: string;
};

export type DeviceConfiguration = {
  sensors: SensorsConfig;
  parameters: ParamsConfig;
  realTime: RealTimeConfig;
  system: SystemConfig;
  lastUpdated: Date;
  lastFetched: Date;
};

export type LastShot = {
  lastUpdated: Date;
  hoseTempAInF: number;
  hoseTempBInF: number;
  manifoldTempAInF: number;
  manifoldTempBInF: number;
  dispenserTempInF: number;
  pumpPressureAInPsi: number;
  pumpPressureBInPsi: number;
  dispensedAInLbs: number;
  dispensedBInLbs: number;
  solventFlow: number;
  solventPressureInPsi: number;
  dispenseTimeInSec: number;
  lifetimeShotCount: number;
  shotsSinceReset: number;
};

export type DeviceDetails = {
  overview: DeviceOverview;
  configuration: DeviceConfiguration;
  lastShot: LastShot;
};

export type DeviceAlarm = {
  id: string;
  businessTimestamp: Date;
  errorCode: number;
  status: string;
  ticketId: string;
  serialNumber: string;
  description: string;
};

export type Cursor<T> = {
  data: T;
  cursor?: string;
};

export type ConfigurationData = SensorsConfig & ParamsConfig;

export type GetAnalyticsDataRequest = {
  id: string;
  startDate: Date;
  endDate: Date;
  samplingWindow: string;
  includeSapImport: boolean;
};

export type GetCustomAnalyticsDataRequest = GetAnalyticsDataRequest & {
  properties: string[];
};

export type AnalyticsData = {
  timestamp: string;
  chemA: number;
  chemB: number;
  psiA: number;
  psiB: number;
  filmUsage: number;
};

export type ErrorData = {
  errorAnalytics: { [key: string]: number | string }[];
  errorCodes: { [key: string]: string };
};

export type DeviceAnalytics = {
  analyticsData: AnalyticsData[];
  errorData: ErrorData;
};

export type CustomAnalyticsData = {
  timestamp: string;
  entries: { [key: string]: number };
};

export type DeviceCustomAnalytics = {
  customAnalyticsData: CustomAnalyticsData[];
};

export type DashboardStatus = {
  onlineStatusCount: number;
  offlineStatusCount: number;
  unknownStatusCount: number;
  telemetryMessagesCount: number;
  openTicketsCount: number;
  completedTicketsCount: number;
};

export type CommandResult = {
  id: string;
  serialNumber: string;
  commandName: string;
  requestBody: unknown;
  responseBody: unknown;
  status: string;
  registeredOn: string;
  sentOn: string;
  processedOn: string;
  acknowledgedOn: string;
};

export type StatusCount = {
  status: string;
  count: number;
};

export type CommandsSummary = Cursor<CommandResult[]> & {
  statusesCount: StatusCount[];
};

export enum PortalRole {
  Read,
  Write,
  Admin,
}

export type RoleAssignment = {
  userId: string;
  name: string;
  principalName: string;
  role: string;
  expiresOn: string | undefined;
};

export type RoleAssignmentExpiration = {
  expiresOn: string;
};

export type UserProfile = {
  roles: string[];
};
