import { baseTheme } from '../baseTheme';

const ALERT_TIMEOUT = 5000;
const { breakpoints, spacing } = baseTheme;

export default {
  defaultProps: {
    autoHideDuration: ALERT_TIMEOUT,
  },
  styleOverrides: {
    root: {
      position: 'static',
      [breakpoints.up('sm')]: {
        minWidth: spacing(30),
      },
    },
  },
};
