import { baseTheme } from '../baseTheme';

const { palette, spacing, typography } = baseTheme;

export const DEFALUT_PAGING_OPTIONS = [5];
export const PAGING_OPTIONS = [5, 10, 25];

export default {
  defaultProps: {
    autoHeight: true,
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    rows: [],
    rowHeight: 40,
    pageSizeOptions: DEFALUT_PAGING_OPTIONS,
    initialState: {
      pagination: { paginationModel: { pageSize: DEFALUT_PAGING_OPTIONS[0] } },
    },
  },
  styleOverrides: {
    root: {
      border: 'none',
      minHeight: spacing(31),
    },
    row: {
      '&.selectable': {
        cursor: 'pointer',
      },
    },
    columnHeaders: {
      borderColor: palette.secondary.main,
    },
    columnSeparator: {
      display: 'none',
    },
    overlay: {
      height: spacing(21),
    },
    virtualScroller: {
      flex: 1,
    },
    virtualScrollerContent: {
      ...typography.body1,
    },
  },
};
