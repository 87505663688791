import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AnalyticsData,
  DeviceAnalytics,
  ErrorData,
  GetAnalyticsDataRequest,
} from 'src/backend';
import { useGetAnalyticsMutation } from 'src/backend/api';
import ErrorAnalytics from 'src/pages/DeviceDetails/ErrorAnalytics';
import PageCard from 'src/components/PageCard';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import BasicAnalytics from './BasicAnalytics';

type Params = {
  id: string;
};

export default function DeviceDetailsAnalysis() {
  const { id } = useParams<Params>();
  const [getAnalytics, { isLoading }] = useGetAnalyticsMutation();
  const [fetchRequired, setFetchRequired] = useState(true);
  const [customDatesDisabled, setCustomDatesDisabled] = useState(true);

  const initialStartDate = dayjs().subtract(28, 'd');
  const initialEndDate = dayjs();
  const initialSamplingWindow = '1d';

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [samplingWindow, setSamplingWindow] = useState(initialSamplingWindow);

  const initialErrorData: ErrorData = {
    errorAnalytics: [],
    errorCodes: {},
  } as ErrorData;
  const [errorData, setErrorData] = useState(initialErrorData);

  const initiaAnalyticsData: AnalyticsData[] = [];
  const [analyticsData, setAnalyticsData] = useState(initiaAnalyticsData);

  const [timeRange, setTimeRange] = useState<string | null>('28-d/1d');

  const [includeSapImport, setIncludeSapImport] = useState(false);
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeSapImport(event.target.checked);
  };

  function setDates(timeAgo: string) {
    const [amount, unit] = timeAgo.split('-');
    const units: 'd' | 'h' = unit as 'd' | 'h';
    const endDate = dayjs();
    const startDate = dayjs().subtract(Number(amount), units);

    setEndDate(endDate);
    setStartDate(startDate);
  }

  const fetchDataForCustomRange = useCallback(() => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const days = end.diff(start, 'd');
    if (days >= 7) {
      setSamplingWindow('1d');
    } else {
      setSamplingWindow('5m');
    }
    setFetchRequired(true);
  }, [startDate, endDate]);

  const onTimeRangeButtonChanged = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
      if (newAlignment === null) {
        return;
      }
      setTimeRange(newAlignment);
      if (newAlignment === 'Custom') {
        setCustomDatesDisabled(false);
      } else {
        setIncludeSapImport(false);
        setCustomDatesDisabled(true);
        const [timeAgo, samplingWindow] = newAlignment?.split('/') ?? [
          '24-h',
          '1d',
        ];
        setSamplingWindow(samplingWindow);
        setDates(timeAgo);
        setFetchRequired(true);
      }
    },
    [],
  );

  const setSeriesData = useCallback((data: DeviceAnalytics) => {
    setErrorData(data.errorData);
    setAnalyticsData(data.analyticsData);
  }, []);

  const fetchData = useCallback(() => {
    const request: GetAnalyticsDataRequest = {
      id: `${id}`,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      samplingWindow,
      includeSapImport,
    };

    getAnalytics({ id, analyticsDataRequest: request })
      .unwrap()
      .then((data) => {
        setSeriesData(data);
      });
  }, [
    endDate,
    setSeriesData,
    getAnalytics,
    id,
    samplingWindow,
    startDate,
    includeSapImport,
  ]);

  useEffect(() => {
    if (fetchRequired) {
      setFetchRequired(false);
      fetchData();
    }
  }, [fetchRequired, fetchData]);

  return (
    <PageCard title="Analysis" icon={<TimelineOutlinedIcon />}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid>
          <div style={{ paddingLeft: '4em', paddingBottom: '3em' }}>
            <ToggleButtonGroup
              value={timeRange}
              exclusive
              onChange={onTimeRangeButtonChanged}
              aria-label="text alignment"
              style={{ marginRight: '10px' }}
            >
              <ToggleButton value="28-d/1d" aria-label="left aligned">
                28 Days
              </ToggleButton>
              <ToggleButton value="7-d/5m" aria-label="centered">
                7 Days
              </ToggleButton>
              <ToggleButton value="24-h/5m" aria-label="right aligned">
                24 Hours
              </ToggleButton>
              <ToggleButton value="1-h/5m" aria-label="right aligned">
                1 Hour
              </ToggleButton>
              <ToggleButton value="Custom" aria-label="justified" size="medium">
                Custom
              </ToggleButton>
            </ToggleButtonGroup>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disabled={customDatesDisabled}
                label="Start date"
                value={startDate}
                slotProps={{
                  textField: {
                    size: 'medium',
                    style: { marginLeft: '5px' },
                  },
                }}
                onChange={(newValue) => {
                  setStartDate(dayjs(newValue));
                }}
              />
              <DateTimePicker
                disabled={customDatesDisabled}
                label="End date"
                value={endDate}
                defaultValue={dayjs(endDate)}
                slotProps={{
                  textField: {
                    size: 'medium',
                    style: { marginLeft: '5px' },
                  },
                }}
                onChange={(newValue) => {
                  setEndDate(dayjs(newValue));
                }}
              />
            </LocalizationProvider>
            <FormControlLabel
              sx={{ marginLeft: '10px' }}
              label="Include SAP import"
              control={
                <Checkbox
                  checked={includeSapImport}
                  disabled={customDatesDisabled}
                  onChange={handleCheckChange}
                />
              }
            />
            <IconButton
              disabled={customDatesDisabled}
              onClick={() => fetchDataForCustomRange()}
            >
              {customDatesDisabled ? '' : <CheckOutlinedIcon />}
            </IconButton>
          </div>
          <ErrorAnalytics errorData={errorData} />
          <Divider />
          <BasicAnalytics analyticsData={analyticsData} />
        </Grid>
      )}
    </PageCard>
  );
}
