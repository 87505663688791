export default {
  MuiAppBar: {
    defaultProps: {
      position: 'relative',
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        justifyContent: 'space-between',
      },
    },
  },
};
