/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { UserProfile } from 'src/backend';

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    userProfile: {
      roles: [],
    },
  },
  reducers: {
    setUserProfile: (state, userProfile) => {
      state.userProfile = userProfile.payload;
    },
  },
});

export const { setUserProfile } = userProfileSlice.actions;

export const getUserProfile = (state: {
  userProfile: { userProfile: UserProfile };
}) => state.userProfile.userProfile;

export default userProfileSlice;
