type QuerySingleParam = string | number | boolean | null | undefined;
export type QueryStringParam = QuerySingleParam | QuerySingleParam[];
export type QueryStringParams = Record<string, QueryStringParam>;

export const parseQueryString = (locationSearch: string) => {
  const paramArr = locationSearch
    .slice(locationSearch.indexOf('?') + 1)
    .split('&');
  const params: Record<string, string> = {};
  paramArr.forEach((param: string) => {
    const [key, val] = param.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
};

export const stringifyQuery = (query: QueryStringParams, baseSearch = '?') =>
  Object.entries(query)
    .filter(([, filterValue]) => filterValue !== undefined)
    .reduce((result, [filterKey, filterValue]) => {
      const value = Array.isArray(filterValue)
        ? filterValue.join(',')
        : filterValue;
      const searchParam = `${filterKey}=${value}`;
      return result ? `${result}&${searchParam}` : searchParam;
    }, baseSearch);
