export const DEVICES_STORE_API_KEY = 'devicesApi';
export const DEVICES_STORE_LIST_TAG = 'devicesList';
export const DEVICES_GET_LIST_API = '/device';
export const DEVICES_GET_ITEM_API = '/device/:id';
export const DEVICES_GET_GATEWAY_API = '/device/gateways/:id';
export const DEVICES_GET_LEAF_API = '/device/leafdevices/:id';
export const DEVICES_GET_CONNECT_STRENGTH_API =
  '/device/leafdevices/:id/connectivitystrength';
export const DEVICES_SEND_COMMAND_API = '/device/:id/command';
export const DEVICES_SEND_GATEWAY_CONFIG_API = '/device/:id/properties';

export const DEVICES_FIRMWARE_UPD_GATEWAY_API = '/device/:id/devicegroup';
export const DEVICES_FIRMWARE_UPD_LEAF_API =
  '/device/leafdevices/:id/firmwareupdate';
