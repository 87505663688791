import { GridValueFormatterParams } from '@mui/x-data-grid';
import { DeviceItem } from 'src/entities/devices';
import {
  deepMergeObjects,
  formatDateTime,
  generatePath,
  getObjFieldByPath,
  setObjProperty,
} from 'src/utils';
import {
  AppConfig,
  ContractGroup,
  ContractGroups,
  ContractRow,
  ContractRowFormat,
} from './types';

export const getContactValueFormatter = (item?: ContractRow) => {
  const { format, values } = item || {};
  if (values) {
    return ({ value }: GridValueFormatterParams) => values[value];
  }
  if (format === ContractRowFormat.DateTime) {
    return ({ value }: GridValueFormatterParams) => formatDateTime(value);
  }
  return undefined;
};

export const getContractGroups = <I = unknown>(
  { contract }: AppConfig,
  modelId?: I,
): ContractGroups =>
  modelId
    ? deepMergeObjects(contract.common, contract[String(modelId)])
    : contract.common;

export const getDeviceBpDataByPath = <T = Record<string, string[]>>(
  config?: AppConfig,
  device?: DeviceItem,
  path?: string,
): T => {
  let result: T = null as T;
  if (config && path) {
    const { blueprint = {} } = config || {};
    const completePath = generatePath(path, device, undefined, '.');
    result = getObjFieldByPath(blueprint, completePath);
  }
  return result;
};

export const getDeviceConfigFieldsByIds = (
  config?: AppConfig,
  device?: DeviceItem,
  ids?: string[],
): ContractRow[] => {
  let result: ContractRow[] = [];
  if (config && ids) {
    const { modelId = '' } = device || {};
    const groups = getContractGroups(config, modelId);
    result = ids?.map((path) => {
      const [group, id] = path.split('.');
      return groups?.[group as ContractGroup].find(({ name }) => name === id);
    }) as ContractRow[];
  }
  return result;
};

export const parseModel = <
  T extends object,
  R extends Record<string, unknown>,
  I = unknown,
>(
  config: AppConfig,
  data: T,
  modelId: I,
  group = ContractGroup.Property,
) => {
  const contractRow = getContractGroups(config, modelId);
  return contractRow[group]?.reduce(
    (acc, { name, path, defaultValue }) => ({
      ...acc,
      [name]: getObjFieldByPath(data, path) ?? defaultValue,
    }),
    {},
  ) as R;
};

export const transferToModel = <
  D extends Record<string, unknown>,
  R extends Record<string, unknown>,
  I = unknown,
>(
  config: AppConfig,
  data: D,
  modelId: I,
  group = ContractGroup.Property,
) => {
  const groups = getContractGroups(config, modelId);
  return groups?.[group].reduce(
    (acc, { name, path }) =>
      data[name] ? setObjProperty(acc, path, data[name]) : acc,
    {},
  ) as R;
};

export const mapContractRows = (items: ContractRow[]) =>
  items.map((item) => ({
    id: item?.name,
    value: item?.name,
    field: item?.name,
    headerName: item?.label,
    valueFormatter: getContactValueFormatter(item),
    flex: 1,
    ...item,
  }));

export const contractRowsToLabelsMap = (items: ContractRow[]) =>
  new Map(items.map(({ name, label }) => [name, label]));
