export default {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
  },
  MuiIconButton: {
    defaultProps: {
      color: 'inherit',
    },
  },
};
