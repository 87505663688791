import { ReactNode } from 'react';
import { InteractionType } from '@azure/msal-browser';
import {
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import AppLoader from 'src/components/AppLoader';
import { scopes } from './config';

type Props = {
  children: ReactNode;
};

function AuthTemplate({ children }: Props) {
  useMsalAuthentication(InteractionType.Redirect, { scopes });

  return (
    <>
      <UnauthenticatedTemplate>
        <AppLoader />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </>
  );
}

export default AuthTemplate;
