import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetDeviceAlarmsQuery } from 'src/backend';
import Loader from 'src/components/Loader';

type Params = {
  id: string;
};

function createDescription(
  ticketId: string,
  serialNumber: string,
  errorCode: number,
  description: string | undefined,
) {
  const ticketDescription = `Work order ${ticketId} created for ${serialNumber} with Error code: ${errorCode}`;
  if (!description) {
    return ticketDescription;
  }

  return `${ticketDescription}. ${description}`;
}

export default function DeviceDetailsTickets() {
  const { id } = useParams<Params>();
  const { data, isLoading } = useGetDeviceAlarmsQuery(id ?? '');

  return isLoading ? (
    <Loader />
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Alarm Id</TableCell>
            <TableCell align="left" text-align="left">
              Business Timestamp
            </TableCell>
            <TableCell align="left" text-align="left">
              Status
            </TableCell>
            <TableCell align="left" text-align="left">
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell component="th" scope="row">
                {moment(row.businessTimestamp).format('LLLL')}
              </TableCell>
              <TableCell align="left">
                <Chip
                  label={row.status}
                  color={row.status === 'Open' ? 'error' : 'success'}
                />
              </TableCell>
              <TableCell align="left">
                {createDescription(
                  row.ticketId,
                  row.serialNumber,
                  row.errorCode,
                  row.description,
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
