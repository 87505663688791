import { v4 as uuidv4 } from 'uuid';
import { formatDateTime, groupArrayBy } from 'src/utils';
import {
  AppConfig,
  ContractGroup,
  contractRowsToLabelsMap,
  getContractGroups,
  parseModel,
} from 'src/entities/config';
import { DeviceItem } from 'src/entities/devices';
import {
  DataResponseItem,
  EventItem,
  EventItemField,
  EventsData,
  EventType,
  TelemetryChartPoints,
  TelemetryData,
  TelemetryLatest,
  TelemetrySummaryItem,
} from './types';

export const mapEventItem = (
  event: DataResponseItem,
  config: AppConfig,
): EventItem => {
  const model = parseModel(config, event, null, ContractGroup.Event);
  const { wu, snr = {} } = event.telemetry;
  const [deviceId] = Object.keys(snr);
  const { ing, egr, upd } = snr[deviceId] || {};
  const type =
    (wu && EventType.Connect) ||
    (ing && EventType.Ingress) ||
    (egr && EventType.Egress) ||
    (upd && EventType.Update) ||
    null;

  return { ...event, ...model, id: uuidv4(), type, description: type };
};

export const mapEventsList = (
  response: DataResponseItem[],
  config: AppConfig,
): EventsData => {
  const items = response.map((item) => mapEventItem(item, config));
  const { info, warning, error } = groupArrayBy(items, EventItemField.Severity);

  return {
    items,
    summary: {
      info: info?.length,
      warning: warning?.length,
      error: error?.length,
    },
  };
};

export const getTelemetryRows = (summary?: TelemetryLatest) =>
  Object.entries(summary || {}).map(([id, value]) => ({
    id,
    ...value,
  })) as TelemetrySummaryItem[];

export const mapTelemetryData = (
  response: DataResponseItem[],
  config: AppConfig,
  { modelId }: DeviceItem,
): TelemetryData => {
  const metricsLatest: TelemetryLatest = {};
  const points: TelemetryChartPoints = {};
  const groups = getContractGroups(config, modelId);
  const labels = contractRowsToLabelsMap(groups.telemetry);

  response.forEach((item) => {
    const { enqueuedTime } = item;
    const telemetry = parseModel<DataResponseItem, Record<string, number>>(
      config,
      item,
      modelId,
      ContractGroup.Telemetry,
    );
    Object.entries(telemetry).forEach(([metric, value]) => {
      if (value) {
        points[metric] = points[metric] || [];
        points[metric]?.push({
          name: new Date(enqueuedTime).toString(),
          value: [formatDateTime(enqueuedTime, 'yyyy/M/d H:m:s'), value],
        });
        if (!metricsLatest[metric]) {
          metricsLatest[metric] = {
            enqueuedTime,
            label: labels.get(metric),
            last: value,
          };
        }
      }
    });
  });

  return {
    summary: getTelemetryRows(metricsLatest),
    points,
  };
};
