export const getUniqArrItems = <T>(arr: T[]) =>
  arr.filter((value, index, self) => self.indexOf(value) === index);

export const getUniqArrItemsBy = <T, R>(arr: T[], fn: (item: T) => R) =>
  getUniqArrItems(arr.map(fn));

export const groupArrayBy = <T>(array: T[], groupBy: keyof T) =>
  array.reduce(
    (grouped, item) => {
      const groupKey = (item[groupBy] || '').toString();
      return {
        ...grouped,
        [groupKey]: [...(grouped[groupKey] || []), item],
      };
    },
    {} as Record<string, T[]>,
  );

export const mergeArrays = <T>(...arrays: (T[] | null | undefined)[]): T[] =>
  arrays.reduce(
    (acc: T[], array: T[] | null | undefined) => [...acc, ...(array || [])],
    [],
  );

export const mergeArraysBy = <
  T extends Record<string, unknown> | undefined,
  V extends Record<string, unknown> | undefined,
>(
  array1: T[],
  array2: V[],
  key = 'id',
): T[] =>
  array1.map((item) => {
    const extra = array2.find((inner) => item?.[key] === inner?.[key]);
    return { ...item, ...extra };
  });

export const splitArrayBy = <T>(
  array: T[],
  isValid: (item: T) => boolean | undefined,
) =>
  array.reduce(
    ([pass, fail]: T[][], elem: T) =>
      isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]],
    [[], []],
  );

export const sortArrayAscBy = <T extends object>(
  array: T[],
  sortKey: keyof T,
): T[] =>
  array.sort((first, second) => {
    if (first[sortKey] > second[sortKey]) {
      return 1;
    }
    if (first[sortKey] < second[sortKey]) {
      return -1;
    }
    return 0;
  });
