import { Navigate } from 'react-router-dom';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Dashboard from 'src/pages/Dashboard';
import DeviceDetails, {
  routes as deviceDetailsRoutes,
} from 'src/pages/DeviceDetails';
import CommandsDashboard from 'src/pages/CommandsDashboard/CommandsDashboard';
import AdminDashboard from 'src/pages/AdminDashboard/AdminDashboard';
import RoleBased from 'src/components/RoleBased';
import Unauthorized from 'src/pages/Unauthorized';
import { PortalRole } from 'src/backend';
import { RouteProps } from './types';
import * as ROUTES from './constants';

export const routes: RouteProps[] = [
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.DASHBOARD} replace />,
  },
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.COMMAND_DIAGNOSTICS} replace />,
  },
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.UNAUTHORIZED} replace />,
  },
  {
    path: ROUTES.DASHBOARD,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Read]}
        component={<Dashboard />}
      />
    ),
    meta: {
      title: 'Dashboard',
      icon: MonitorHeartOutlinedIcon,
      accessRole: PortalRole[PortalRole.Read],
    },
  },
  {
    path: ROUTES.COMMAND_DIAGNOSTICS,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Read]}
        component={<CommandsDashboard />}
      />
    ),
    meta: {
      title: 'Command Diagnostics',
      icon: AnalyticsOutlinedIcon,
      accessRole: PortalRole[PortalRole.Read],
    },
  },
  {
    path: ROUTES.ADMIN_PANEL,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Admin]}
        component={<AdminDashboard />}
      />
    ),
    meta: {
      title: 'Admin Panel',
      icon: ManageAccountsOutlinedIcon,
      accessRole: PortalRole[PortalRole.Admin],
    },
  },
  {
    path: ROUTES.DEVICE_DETAILS,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Read]}
        component={<DeviceDetails />}
      />
    ),
    children: deviceDetailsRoutes,
    meta: {
      title: 'Device Details',
      navLink: false,
      accessRole: PortalRole[PortalRole.Read],
    },
  },
  {
    path: ROUTES.UNAUTHORIZED,
    element: <Unauthorized />,
    meta: {
      title: 'Unauthorized',
      navLink: false,
    },
  },
];
