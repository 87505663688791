import Loader from 'src/components/Loader';
import AppLogo from 'src/components/AppLogo';

function AppLoader() {
  return (
    <Loader color="whitesmoke" sx={{ background: '#142A4D', width: '100%' }}>
      <AppLogo />
    </Loader>
  );
}
export default AppLoader;
