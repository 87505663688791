import { EventType } from '../data';

export enum DeviceType {
  Ams = 'ams',
  Es8 = 'es8',
  Gateway = 'gateway',
}

export enum DeviceItemField {
  Id = '$dtId',
  Type = 'type',
  Address = 'address',
  Connected = 'connected',
  SignalStrength = 'signalStrength',
  SwVersion = 'swVersion',
}

export enum DeviceChannelType {
  Cellular = 'cellular',
  Wifi = 'wifi',
}

export enum DeviceCommandType {
  SetDevice = 'SetDevice',
  Reboot = 'Reboot',
  Wink = 'Wink',
}

export type DeviceItemContextTwinItem = {
  $dtId: string;
  $etag: string;
  address?: string;
  city?: string;
  name: string;
  state?: string;
};

export enum DevicesSummaryField {
  types = 'deviceTypes',
  gateways = 'totalGateways',
  devices = 'totalDevices',
  sites = 'totalSites',
}

export type DeviceItemContextTwins = {
  facility: DeviceItemContextTwinItem;
  floor: DeviceItemContextTwinItem;
  room: DeviceItemContextTwinItem;
  unit: DeviceItemContextTwinItem;
};

export type DeviceItemInfo = {
  dvcTenantID: string;
  manufacturer: string;
  model: string;
  osName: string;
  processorArchitecture: string;
  processorManufacturer: string;
  swVersion: string;
  totalMemory: number;
  totalStorage: number;
};

export type DeviceItemWifi = {
  FrequencyMHz: number;
  IPAddress: string;
  MAC: string;
  SSID: string;
};

export type DeviceItemMetaData = {
  $model: string;
};

export type DeviceItemMsgBuf = {
  bufCnt?: number;
  bufExp?: number;
  bufMsgSndAttempt?: number;
  bufMsgTmt?: number;
  bufSize?: number;
};

export type DeviceItemRssiFilter = {
  egressRSSI?: number;
  ingressRSSI?: number;
};

export type DeviceItemSensorRes = {
  msgBuf?: DeviceItemMsgBuf;
  smRssiFilter: { default: DeviceItemRssiFilter };
  dspTm?: number;
  dwell: number;
  sense?: number;
  smIngressDebounce?: number;
  smEgressTimeout?: number;
  snrUpdInterval?: number;
  stsInterval?: number;
  stsTm?: number;
};

export type DeviceItemSensor = DeviceItemMsgBuf &
  DeviceItemRssiFilter & {
    dspTm?: number;
    dwell: number;
    ingressRSSI?: number;
    egressRSSI?: number;
    sense?: number;
    smIngressDebounce?: number;
    smEgressTimeout?: number;
    snrUpdInterval?: number;
    stsInterval?: number;
    stsTm?: number;
  };

export type DeviceResponseItem = {
  $dtId: string;
  $etag: string;
  $metadata: DeviceItemMetaData;
  connected: boolean;
  context?: Record<string, string>;
  contextTwins?: DeviceItemContextTwins;
  deviceinformation?: DeviceItemInfo;
  name: string;
  organizationPath: string;
  powerType: string;
  provisioned: boolean;
  sensormanager: DeviceItemSensorRes;
  type: DeviceType;
  wifi: DeviceItemWifi;
};

export type DeviceConnectData = {
  id?: string;
  connected?: boolean;
  eventType: EventType;
  gatewayId: string;
  lastUpdated: string;
  rssi: number;
};

export type DeviceItem = Omit<
  DeviceResponseItem,
  | 'context'
  | 'contextTwins'
  | 'deviceinformation'
  | 'sensormanager'
  | 'wifi'
  | '$metadata'
> &
  Partial<DeviceConnectData> &
  Partial<DeviceItemInfo> &
  Partial<DeviceItemSensor> &
  Partial<DeviceItemWifi> & {
    id: string;
    modelId: string;
    channel?: DeviceChannelType;
  };

export type DevicesSummary = {
  deviceTypes: number;
  totalGateways: number;
  totalDevices: number;
  totalSites?: number;
};

export type DevicesData = {
  items: DeviceItem[];
  summary: DevicesSummary;
};

export type DeviceOverviewGridSummary = {
  total: number;
  online: number;
  offline: number;
};

export type DeviceOverviewGridData = {
  items: DeviceItem[];
  summary: DeviceOverviewGridSummary;
};

export type DeviceCommandParams = Partial<DeviceItem> & {
  action: DeviceCommandType;
};

export type DeviceFirmWareParams = {
  id?: string;
  versionId: string;
};
