import PageCard from 'src/components/PageCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams } from 'react-router-dom';
import { CustomerAddress, useGetDeviceDetailsQuery } from 'src/backend';
import DataField from 'src/components/DataField/DataField';
import { Divider, Stack, Typography } from '@mui/material';
import OnOff from 'src/components/OnOff';

type Params = {
  id: string;
};

function formatAddress(address?: CustomerAddress) {
  if (!address) {
    return '-';
  }
  const hn = address.houseNumber ? `${address.houseNumber} ` : '';
  const st = address.street ? `${address.street}` : '';
  const street = (hn + st).trim();
  const streetLine = street ? (
    <Typography variant="caption" align="right">
      {street}
    </Typography>
  ) : (
    ''
  );
  const regCode = address.regionCode ? `${address.regionCode}` : '';
  const postCode = address.postCode ? `${address.postCode}` : '';
  const cty = address.city ? `${address.city}` : '';

  const city = `${regCode + postCode} ${cty}`.trim();
  const cityLine = city ? (
    <Typography variant="caption" align="right">
      {city}
    </Typography>
  ) : (
    ''
  );
  const countryLine = address.country ? (
    <Typography variant="caption" align="right">
      {address.country}
    </Typography>
  ) : (
    ''
  );

  return (
    <Stack>
      {streetLine}
      {cityLine}
      {countryLine}
    </Stack>
  );
}

export default function DeviceDetailsOverview() {
  const { id } = useParams<Params>();
  const { data, isLoading } = useGetDeviceDetailsQuery(id ?? '');
  const overview = data?.overview;
  return isLoading ? (
    ''
  ) : (
    <PageCard title="Overview" icon={<InfoOutlinedIcon />}>
      <DataField label="Twin ID" value={data?.overview.twinId} />
      <DataField label="Serial number" value={data?.overview.serialNumber} />
      <DataField
        label="Connected"
        value={
          <OnOff
            value={overview?.isConnected}
            onText="Online"
            offText="Offline"
            width="10rem"
          />
        }
      />
      <Divider sx={{ paddingTop: '1em' }} />
      <DataField label="Customer name" value={overview?.customerName} />
      <DataField label="Customer ID" value={overview?.customerId} />
      <DataField label="Technician" value={overview?.assignedTechnician} />
      <Divider sx={{ paddingTop: '1em' }} />
      <DataField
        label="Address"
        value={formatAddress(overview?.customerAddress)}
      />
    </PageCard>
  );
}
