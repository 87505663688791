import { Filter } from 'src/components/DataGridToolbar';
import { SiteItem, SitePositionResponse } from './types';
import { SITE_LOCATION_CONF_RANGE } from './constants';

export const getFilterBySite = (item?: SiteItem) => {
  const { city, name } = item || {};
  return item
    ? Object.entries({ facility: name, city }).map(
        ([field, value]) => ({ field, value }) as Filter,
      )
    : [];
};

export const getSiteFullAdress = (item: SiteItem) =>
  `${item?.address}, ${item?.city}, ${item?.state}`;

export const mapSitePositionResponse = (response: SitePositionResponse) => {
  const [item] = response.results;
  const { position, matchConfidence } = item || {};
  const isValidPosition = matchConfidence?.score >= SITE_LOCATION_CONF_RANGE;
  return isValidPosition && position;
};
