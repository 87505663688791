import { Components } from '@mui/material/styles';
import MuiCssBaseline from './global';
import MuiChip from './chip';
import MuiCircularProgress from './loader';
import MuiContainer from './container';
import MuiDataGrid from './table';
import MuiGrid from './grid';
import MuiSnackbar from './snackbar';
import MuiSvgIcon from './icon';
import MuiDrawer from './sidebar';
import MuiAlert from './alert';
import MuiButton from './button';
import MuiCard from './card';
import MuiDialog from './dialog';
import MuiInput from './input';
import MuiHeader from './header';
import MuiList from './list';
import MuiTabs from './tabs';

export const components = {
  MuiCssBaseline,
  MuiChip,
  MuiCircularProgress,
  MuiContainer,
  MuiDataGrid,
  MuiGrid,
  MuiSnackbar,
  MuiSvgIcon,
  MuiDrawer,
  ...MuiAlert,
  ...MuiButton,
  ...MuiCard,
  ...MuiDialog,
  ...MuiInput,
  ...MuiHeader,
  ...MuiList,
  ...MuiTabs,
} as Partial<Components>;
