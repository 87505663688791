import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ReqMethod } from 'src/helpers';
import { getKustoQlQuery } from 'src/utils';
import {
  DATA_STORE_API_KEY,
  DATA_EXECUTE_API,
  DATA_TABLE_EVENTS,
  DATA_TABLE_TIME_SERIES,
} from './constants';
import { EventsData, TelemetryData } from './types';
import { mapEventsList, mapTelemetryData } from './utils';
import { DeviceItem, devicesComponentsMap } from '../devices';

export const dataApi = createApi({
  reducerPath: DATA_STORE_API_KEY,
  baseQuery: baseQuery({ path: DATA_EXECUTE_API }),
  endpoints: ({ query }) => ({
    getEvents: query<EventsData, string | undefined>({
      query: (deviceId) => ({
        method: ReqMethod.Post,
        body: {
          query: getKustoQlQuery({
            id: DATA_TABLE_EVENTS,
            where: { deviceId },
          }),
        },
      }),
      transformResponse: mapEventsList,
    }),
    getTelemetry: query<TelemetryData, DeviceItem>({
      query: ({ id, type }) => ({
        method: ReqMethod.Post,
        body: {
          query: getKustoQlQuery({
            id: DATA_TABLE_TIME_SERIES,
            limit: 1000,
            where: { deviceId: id, component: devicesComponentsMap.get(type) },
          }),
        },
      }),
      transformResponse: mapTelemetryData,
    }),
  }),
});

export const { useGetEventsQuery, useGetTelemetryQuery } = dataApi;
