import { Chip, Typography } from '@mui/material';

type Params = {
  value?: boolean;
  onText?: string;
  offText?: string;
  unknownText?: string;
  width?: string;
};

function pickText(
  onText: string,
  offText: string,
  unknownText: string,
  value?: boolean,
) {
  if (value === undefined || value === null) {
    return unknownText;
  }

  return value ? onText : offText;
}

function pickColor(value?: boolean): 'default' | 'success' | 'error' {
  if (value === undefined || value === null) {
    return 'default';
  }
  return value ? 'success' : 'error';
}

export default function OnOff({
  value,
  onText = 'On',
  offText = 'Off',
  unknownText = 'Unknown',
  width = '5rem',
}: Params) {
  const text = pickText(onText, offText, unknownText, value);
  const color = pickColor(value);

  return (
    <Chip
      label={<Typography variant="caption">{text}</Typography>}
      color={color}
      sx={{ width }}
    />
  );
}
