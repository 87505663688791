import { useParams } from 'react-router-dom';
import { ConfigurationData, useGetDeviceDetailsQuery } from 'src/backend';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import DeviceDetailsConfigurationSensors from './DeviceDetailsConfigurationSensors';
import DeviceDetailsConfigurationParameters from './DeviceDetailsConfigurationParameters';
import { DeviceDetailsConfigurationRealTime } from './DeviceDetailsConfigurationRealTime';
import DeviceDetailsConfigurationSystem from './DeviceDetailsConfigurationSystem';

type Params = {
  id: string;
};

export default function DeviceDetailsConfiguration() {
  const { id } = useParams<Params>();
  const { data, refetch } = useGetDeviceDetailsQuery(id ?? '');
  const { sensors, parameters, realTime, system, lastUpdated, lastFetched } =
    data?.configuration ?? {};
  const [refetchRequired, setRefetchRequired] = useState(false);

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
    }
  }, [refetchRequired, refetch]);

  const configurationData = {
    ...parameters,
    ...sensors,
  } as ConfigurationData;

  return (
    <Grid container spacing={2}>
      <DeviceDetailsConfigurationParameters
        lastUpdated={lastUpdated}
        lastFetched={lastFetched}
        parameters={parameters}
        configurationData={configurationData}
        setRefetchRequired={(refresh: boolean) => setRefetchRequired(refresh)}
      />
      <DeviceDetailsConfigurationRealTime realTime={realTime} xs={6} />
      <DeviceDetailsConfigurationSensors sensors={sensors} xs={3} />
      <DeviceDetailsConfigurationSystem system={system} xs={3} />
    </Grid>
  );
}
