import { baseTheme } from '../baseTheme';

const { spacing } = baseTheme;

export default {
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: spacing(4),
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      primaryTypographyProps: {
        color: 'inherit',
        variant: 'body2',
      },
    },
  },
};
