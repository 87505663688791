import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

type Props = ButtonProps & {
  loading?: boolean;
};

function LoadingButton({ disabled, loading, ...props }: Props) {
  return (
    <Button
      endIcon={loading && <CircularProgress size="2.3rem" />}
      disabled={disabled || loading}
      {...props}
    />
  );
}

export default LoadingButton;
