import { baseTheme } from '../baseTheme';

const { spacing } = baseTheme;

export default {
  defaultProps: {
    maxWidth: false,
  },
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      paddingBottom: spacing(2),
    },
  },
};
