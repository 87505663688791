import { IconButton, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid';
import PageCard from 'src/components/PageCard';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import LoadingIconButton from 'src/components/LoadingIconButton';
import {
  RoleAssignment,
  RoleAssignmentExpiration,
  useDeleteRoleAssignmentMutation,
  useListRoleAssignmentsQuery,
  useUpdateRoleAssignmentExpirationMutation,
} from 'src/backend';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useState, useEffect, useCallback, useMemo } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CreateRoleAssignmentForm from './CreateRoleAssignmentForm';

dayjs.extend(utc);

function AdminPanel() {
  const roleExpirationToUpdate: { [key: string]: string | undefined } = useMemo(
    () => ({}),
    [],
  );
  function getRowId(row: RoleAssignment) {
    return `${row.userId}__${row.role}`;
  }

  function showConfirmationButton(rowId: string) {
    const button = document.getElementById(rowId);
    if (button) {
      button.style.display = '';
    }
  }

  function hideConfirmationButton(btnId: string) {
    const button = document.getElementById(btnId);
    if (button) {
      button.style.display = 'none';
    }
  }

  const setExpirationDateToUpdate = useCallback(
    (rowId: string, val: string | null) => {
      roleExpirationToUpdate[rowId] = val ?? undefined;
      showConfirmationButton(rowId);
    },
    [roleExpirationToUpdate],
  );
  const [refetchRequired, setRefetchRequired] = useState(false);

  const updateExpiration = useUpdateRoleAssignmentExpirationMutation();
  const apiRef = useGridApiRef();
  const updateRoleAssignment = useCallback(
    (rowId: string) => {
      const roleAssignmentExpiration = {
        expiresOn: roleExpirationToUpdate[rowId],
      } as RoleAssignmentExpiration;
      const [userId, role] = rowId.split('__');
      updateExpiration[0]({ userId, role, roleAssignmentExpiration })
        .unwrap()
        .then((val) => {
          apiRef.current.updateRows([
            { userId: val.userId, role: val.role, expiresOn: val.expiresOn },
          ]);
        })
        .catch()
        .finally(() => hideConfirmationButton(rowId));
    },
    [apiRef, roleExpirationToUpdate, updateExpiration],
  );

  const deleteRole = useDeleteRoleAssignmentMutation();
  const deleteRoleAssignment = useCallback(
    (rowId: string) => {
      const [userId, role] = rowId.split('__');
      deleteRole[0]({ userId, role })
        .unwrap()
        .then((val) => {
          apiRef.current.updateRows([
            { userId: val.userId, role: val.role, expiresOn: val.expiresOn },
          ]);
        })
        .catch();
    },
    [apiRef, deleteRole],
  );

  const columns: GridColDef<RoleAssignment>[] = [
    {
      field: 'userId',
      headerName: 'User ID',
    },
    {
      field: 'name',
      headerName: 'User Name',
      flex: 1,
    },
    {
      field: 'principalName',
      headerName: 'User Principal Name',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'User Role',
      flex: 1,
    },
    {
      field: 'expiresOn',
      headerName: 'Expires On',
      flex: 2,
      renderCell: (params) => {
        const initDate = params.value ? dayjs(params.value) : null;
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={initDate}
              timezone="UTC"
              slotProps={{
                textField: {
                  size: 'small',
                  variant: 'standard',
                  InputProps: {
                    disableUnderline: true,
                    placeholder: 'No expiration set',
                    disabled: true,
                  },
                },
                actionBar: {
                  actions: ['clear', 'accept'],
                },
              }}
              onAccept={(val) =>
                setExpirationDateToUpdate(
                  params.id.toString(),
                  val?.toISOString() ?? null,
                )
              }
              minutesStep={1}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#142A4D',
                },
              }}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        const idStr = params.id.toString();
        return (
          <>
            <LoadingIconButton
              onClick={() => deleteRoleAssignment(idStr)}
              loading={deleteRole[1].isLoading}
            >
              <Tooltip title="Delete role assignment">
                <DeleteOutlineOutlinedIcon />
              </Tooltip>
            </LoadingIconButton>
            <LoadingIconButton
              id={idStr}
              onClick={() => updateRoleAssignment(idStr)}
              style={{ display: 'none' }}
              sx={{ color: '#142A4D !important' }}
              loading={updateExpiration[1].isLoading}
            >
              <Tooltip title="Save changes">
                <CheckOutlinedIcon />
              </Tooltip>
            </LoadingIconButton>
          </>
        );
      },
    },
  ];
  const { data, isLoading, isFetching, refetch } = useListRoleAssignmentsQuery(
    {},
  );
  const [isPopupOpen, openPopup] = useState(false);
  const postCreateAction = useCallback(
    (roleAssignment: RoleAssignment | undefined) => {
      openPopup(false);
      if (roleAssignment) {
        apiRef.current.updateRows([roleAssignment]);
      }
    },
    [apiRef],
  );

  const [pageState, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const onPaginationModelChange = (m: GridPaginationModel) => {
    if (m.page !== pageState.page) {
      setPaginationModel({
        page: m.page,
        pageSize: pageState.pageSize,
      });
    }
  };

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
    }
  }, [refetchRequired, refetch]);

  return (
    <>
      <CreateRoleAssignmentForm
        isOpen={isPopupOpen}
        closeForm={(roleAssignment: RoleAssignment | undefined) =>
          postCreateAction(roleAssignment)
        }
      />
      <PageCard
        title="Role assignments"
        icon={<TableRowsOutlinedIcon />}
        sx={{
          '& .MuiCardHeader-root': {
            height: '80px',
          },
        }}
        contentSx={{ display: 'block' }}
        action={
          <>
            <IconButton onClick={() => openPopup(true)}>
              <Tooltip title="Add role assignment">
                <AddCircleOutlineOutlinedIcon />
              </Tooltip>
            </IconButton>
            <LoadingIconButton
              loading={isLoading || isFetching}
              onClick={() => setRefetchRequired(true)}
            >
              <Tooltip title="Refresh">
                <CachedOutlinedIcon fontSize="large" />
              </Tooltip>
            </LoadingIconButton>
          </>
        }
      >
        <DataGrid
          apiRef={apiRef}
          getRowId={(row) => getRowId(row)}
          getRowClassName={(params) => {
            if (params.row.expiresOn && dayjs(params.row.expiresOn) < dayjs())
              return 'role-expired';

            return '';
          }}
          columns={columns}
          columnVisibilityModel={{ userId: false }}
          rows={data?.data ?? []}
          loading={isFetching}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          sx={{
            '& .role-expired': {
              backgroundColor: 'rgba(236, 240, 241, 0.5)',
              color: 'lightgrey',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-overlay': {
              height: '100%',
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          pageSizeOptions={[10]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          paginationMode="client"
          filterMode="client"
          paginationModel={pageState}
          onPaginationModelChange={onPaginationModelChange}
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </PageCard>
    </>
  );
}

export default AdminPanel;
