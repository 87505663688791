import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { SxProps, Theme, Tooltip } from '@mui/material';

type Params = {
  status: string;
  count?: number;
  showTooltip: boolean;
};

type Props = {
  status: string;
  icon: JSX.Element;
  count?: number;
  showTooltip: boolean;
};

function StatusIcon({ status, icon, count, showTooltip }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginRight: '15px',
      }}
    >
      {showTooltip ? <Tooltip title={status}>{icon}</Tooltip> : icon}
      <span>{count}</span>
    </div>
  );
}

function prepareStatusIcon(
  status: string,
  showTooltip: boolean,
  count?: number,
): JSX.Element {
  const sx: SxProps<Theme> = { marginRight: '5px' };

  if (status === 'Scheduled') {
    const icon = <RadioButtonUncheckedIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  if (status === 'Pending') {
    const icon = <RadioButtonCheckedIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  if (status === 'Processed') {
    const icon = <CheckCircleOutlineIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  if (status === 'Acknowledged') {
    const icon = <CheckCircleIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  const icon = <UnpublishedIcon sx={sx} />;
  return (
    <StatusIcon
      status={status}
      icon={icon}
      count={count}
      showTooltip={showTooltip}
    />
  );
}

export default function StatusSummary({ status, count, showTooltip }: Params) {
  return prepareStatusIcon(status, showTooltip, count);
}
