import { PortalRole, UserProfile } from '.';

export function hasRole(profile: UserProfile, role: string): boolean {
  if (role === PortalRole[PortalRole.Read]) {
    return profile.roles.some(
      (x) => x === role || x === PortalRole[PortalRole.Write],
    );
  }
  return profile.roles.some((x) => x === role);
}
