/* eslint-disable no-console */
import {
  ConfigurationData,
  ParamsConfig,
  PortalRole,
  useRefreshConfigurationMutation,
} from 'src/backend';
import DataField from 'src/components/DataField/DataField';
import {
  Backdrop,
  Box,
  Divider,
  Fade,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import PageCard from 'src/components/PageCard';
import { TuneOutlined } from '@mui/icons-material';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import LoadingIconButton from 'src/components/LoadingIconButton';
import { useCallback, useEffect, useState } from 'react';
import UpdateConfigurationForm from 'src/components/UpdateConfigurationForm/UpdateConfigurationForm';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import RoleBased from 'src/components/RoleBased';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type RouteParams = {
  id: string;
};

type Params = {
  parameters: ParamsConfig | undefined;
  lastUpdated: Date | undefined;
  lastFetched: Date | undefined;
  xs?: number | undefined;
  configurationData: ConfigurationData;
  setRefetchRequired: (refresh: boolean) => void;
};

export default function DeviceDetailsConfigurationParameters({
  parameters,
  lastUpdated,
  lastFetched,
  xs = 12,
  configurationData,
  setRefetchRequired: onRefetchRequired,
}: Params) {
  const [doRefetch, triggerRefetch] = useState(false);
  const [startTimer, runTimer] = useState(false);
  const [isConfirmationModalOpen, displayConfirmationModal] = useState(false);
  const [isUpdateFormOpen, openUpdateForm] = useState(false);
  const { id } = useParams<RouteParams>();
  const [refreshConfiguration, { isLoading }] =
    useRefreshConfigurationMutation();

  useEffect(() => {
    if (doRefetch) {
      triggerRefetch(false);
      onRefetchRequired(true);
    }
  }, [doRefetch, onRefetchRequired]);

  useEffect(() => {
    if (startTimer) {
      const timeout = setTimeout(() => {
        displayConfirmationModal(false);
        runTimer(false);
        triggerRefetch(true);
      }, 10000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [startTimer]);

  const showInfoPopup = useCallback(() => {
    refreshConfiguration(id);
    runTimer(true);
    displayConfirmationModal(true);
  }, [id, refreshConfiguration]);

  const postUpdateAction = useCallback(
    (refreshData: boolean) => {
      console.log(refreshData);
      openUpdateForm(false);
      onRefetchRequired(refreshData);
    },
    [onRefetchRequired],
  );

  return (
    <>
      <UpdateConfigurationForm
        isOpen={isUpdateFormOpen}
        configurationData={configurationData}
        closeForm={(refreshData: boolean) => postUpdateAction(refreshData)}
      />
      <Modal
        open={isConfirmationModalOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        disableEscapeKeyDown
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isConfirmationModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Get configuration
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Get configuration command has been sent. Refreshing page in 10
              seconds
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Grid item xs={xs}>
        <PageCard
          title="Parameters"
          icon={<TuneOutlined />}
          action={
            <RoleBased
              requiredRole={PortalRole[PortalRole.Write]}
              component={
                <>
                  <LoadingIconButton
                    loading={isLoading}
                    onClick={() => showInfoPopup()}
                  >
                    <Tooltip title="Get configuration">
                      <GetAppOutlinedIcon fontSize="large" />
                    </Tooltip>
                  </LoadingIconButton>
                  <LoadingIconButton
                    loading={false}
                    onClick={() => openUpdateForm(true)}
                  >
                    <Tooltip title="Update configuration">
                      <BuildOutlinedIcon />
                    </Tooltip>
                  </LoadingIconButton>
                </>
              }
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">Last updated</Typography>
              <Typography variant="body2" sx={{ paddingBottom: '2em' }}>
                {lastUpdated ? moment(lastUpdated).format('LLLL') : '-'}
              </Typography>
              <Divider />
              <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                Seal temperature
              </Typography>
              <DataField label="Cut (0-200)" value={parameters?.sealTempCut} />
              <DataField
                label="Bottom (0-200)"
                value={parameters?.sealTempBottom}
              />
              <DataField label="Top (0-200)" value={parameters?.sealTempTop} />
              <DataField label="Edge (0-30)" value={parameters?.sealTempEdge} />

              <Divider sx={{ paddingTop: '1em' }} />
              <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                Film tension
              </Typography>
              <DataField
                label="Idle (0-255)"
                value={parameters?.filmTensionIdle}
              />
              <DataField
                label="Stop (0-255)"
                value={parameters?.filmTensionStop}
              />
              <DataField
                label="Advance (0-255)"
                value={parameters?.filmTensionAdvance}
              />
              <Divider sx={{ paddingTop: '1em' }} />

              <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                Chemical
              </Typography>
              <DataField
                label="Temperature (80-160)"
                value={parameters?.chemTemp}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Last fetched</Typography>
              <Typography variant="body2" sx={{ paddingBottom: '2em' }}>
                {lastFetched ? moment(lastFetched).format('LLLL') : '-'}
              </Typography>
              <Divider />
              <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                Vent
              </Typography>
              <DataField label="Length (0-50)" value={parameters?.ventLength} />
              <DataField
                label="Position (0-255)"
                value={parameters?.ventPosition}
              />
              <Divider sx={{ paddingTop: '1em' }} />
              <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                Flow
              </Typography>
              <DataField
                label="Flow rate (10-50)"
                value={parameters?.foamFlowRate}
              />
              <DataField
                label="Ratio A:B (50-150)"
                value={parameters?.foamRatioAb}
              />
              <Divider sx={{ paddingTop: '1em' }} />
              <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                Foam
              </Typography>
              <DataField
                label="Fill factor(10-50)"
                value={parameters?.foamFillFactor}
              />
              <DataField
                label="Stop position (0-255)"
                value={parameters?.stopPosition}
              />
              <Divider sx={{ paddingTop: '1em' }} />
              <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                Bag sequence delay
              </Typography>
              <DataField
                label="Film Deploy (0.02-1.0)"
                value={parameters?.bagSequenceDelayFilmDeploy}
              />
              <DataField
                label="Bag Delay (0-1.5)"
                value={parameters?.bagSequenceDelayBagDelay}
              />
              <DataField
                label="Foam Drip (0-1.0)"
                value={parameters?.bagSequenceDelayFoamDrip}
              />
            </Grid>
          </Grid>
        </PageCard>
      </Grid>
    </>
  );
}
