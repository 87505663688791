import { baseTheme } from '../baseTheme';

const { spacing } = baseTheme;

export default {
  MuiCard: {
    styleOverrides: {
      root: {
        flex: 1,
        overflow: 'initial',
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h2',
      },
      subheaderTypographyProps: {
        variant: 'body1',
      },
    },
    styleOverrides: {
      action: {
        margin: 0,
      },
      avatar: {
        alignSelf: 'flex-start',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: spacing(1.5, 3),
      },
    },
  },
};
