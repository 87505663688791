import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ReqMethod } from 'src/helpers';
import { CONFIG_STORE_API_KEY } from './constants';
import { AppConfig } from './types';

export const configApi = createApi({
  reducerPath: CONFIG_STORE_API_KEY,
  baseQuery: baseQuery({ baseUrl: '' }),
  endpoints: ({ query }) => ({
    getAppConfig: query<AppConfig, void>({
      query: () => ({
        url: import.meta.env.REACT_APP_CONFIG_API,
        method: ReqMethod.Get,
      }),
    }),
  }),
});

export const { useGetAppConfigQuery } = configApi;
