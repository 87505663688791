import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ReqMethod } from 'src/helpers';
import {
  SITES_GET_LIST_API,
  SITES_GET_LOCATION,
  SITES_STORE_API_KEY,
} from './constants';
import { SiteItem, SitePositionMapData } from './types';
import { getSiteFullAdress, mapSitePositionResponse } from './utils';

export const sitesApi = createApi({
  reducerPath: SITES_STORE_API_KEY,
  baseQuery: baseQuery(),
  endpoints: ({ query }) => ({
    getSites: query<SiteItem[], void>({
      query: () => ({
        url: SITES_GET_LIST_API,
        method: ReqMethod.Get,
      }),
    }),
    getSitePosition: query<SitePositionMapData | false, SiteItem>({
      query: (item) => ({
        url: SITES_GET_LOCATION,
        method: ReqMethod.Get,
        queryParams: {
          query: getSiteFullAdress(item),
          'subscription-key': import.meta.env.REACT_APP_MAPS_SUBSCRIPTION_KEY,
        },
      }),
      transformResponse: mapSitePositionResponse,
    }),
  }),
});

export const { useGetSitesQuery, useGetSitePositionQuery } = sitesApi;
