import AppRouter from 'src/router';
import AppLayout from 'src/components/AppLayout';
import AppProviders from 'src/components/AppProviders';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
import { persistor } from './store/store';
import UserRolesProvider from './components/UserRolesProvider';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProviders>
          <UserRolesProvider>
            <AppLayout>
              <AppRouter />
            </AppLayout>
          </UserRolesProvider>
        </AppProviders>
      </PersistGate>
    </Provider>
  );
}

export default App;
