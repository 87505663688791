import { generatePath as generateRoutePath } from 'react-router-dom';
import { mapObject } from './object';

type Param = string | number | boolean | null | undefined;
type Params = Record<string, Param>;

export const generatePath = (
  path: string,
  params?: Params,
  defaultValue?: string,
  separator?: string,
): string => {
  const defaultParamsValue = defaultValue || 'all';
  const transformedPath = separator ? path.split(separator).join('/') : path;
  const transformedParams = mapObject(params || {}, (key, value) =>
    value ? value.toString() : defaultParamsValue,
  );
  const gereratedPath = params
    ? generateRoutePath(transformedPath, transformedParams)
    : path;

  if (separator) {
    return gereratedPath.split('/').join(separator);
  }
  return gereratedPath;
};
