import { baseTheme } from '../baseTheme';

const { palette, spacing, typography } = baseTheme;

export default {
  MuiAlert: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        ...typography.caption,
        color: palette.common.white,
        width: '100%',
      },
      action: {
        paddingLeft: spacing(2),
      },
      filledWarning: {
        color: palette.common.black,
      },
      icon: {
        alignItems: 'center',
      },
    },
  },
};
