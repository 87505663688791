import React, { ReactNode, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import AlertContext from './AlertContext';
import AlertsContainer from './AlertsContainer';
import { useApiErrorAlert } from './hooks';
import { AlertType, AlertHandlerProps } from './types';

type Props = {
  children: React.ReactNode;
};

type AlertProps = {
  id?: string | number;
  open: boolean;
  type?: AlertType;
  text?: ReactNode;
  title?: string;
};

type AlertState = AlertProps[];

function AlertProvider({ children }: Props) {
  const [state, setState] = React.useState<AlertState>([]);

  const showAlert = useCallback(({ id, ...rest }: AlertHandlerProps) => {
    const newItem = { id: id || uuidv4(), open: true, ...rest };
    setState((state) => {
      if (state.find((a) => a.text === rest.text)) {
        return state;
      }
      return [...state, newItem];
    });
  }, []);
  const closeAlert = useCallback(
    (
      event: React.SyntheticEvent | Event,
      reason: string | null,
      index: number,
    ) => {
      if (reason === 'clickaway') {
        return;
      }
      state.splice(index, 1);
      setState([...state]);
    },
    [state],
  );
  const contextValue = useMemo(() => ({ showAlert }), [showAlert]);

  useApiErrorAlert(showAlert);

  return (
    <AlertContext.Provider value={contextValue}>
      {!!state.length && (
        <AlertsContainer>
          {state.map(({ id, open, type, title, text }, index) => (
            <Snackbar
              key={id}
              open={open}
              onClose={(...args) => closeAlert(...args, index)}
            >
              <Alert
                onClose={(event) => closeAlert(event, null, index)}
                severity={type}
              >
                {title && <AlertTitle>{title}</AlertTitle>}
                {text}
              </Alert>
            </Snackbar>
          ))}
        </AlertsContainer>
      )}
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
