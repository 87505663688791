/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomAnalyticsData } from 'src/backend';
import ReactECharts from 'echarts-for-react';
import { useRef } from 'react';
import dayjs from 'dayjs';

type Params = {
  customAnalyticsData: CustomAnalyticsData[];
};

function CustomAnalytics({ customAnalyticsData }: Params) {
  const emptyOptions = {
    grid: {
      top: 100,
    },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {},
    series: [],
  };
  const chartRef = useRef(null as any);
  function extractCustomAnalyticSeries() {
    type Serie = {
      name: string;
      data: number[];
      type: 'line';
      label: {
        show: true;
      };
      emphasis: {
        focus: 'series';
      };
    };
    type CustomSeries = { [key: string]: Serie };
    const extractedCustomSeries: CustomSeries = {};

    const timeSeries: string[] = [];

    customAnalyticsData.forEach((x) => {
      timeSeries.push(dayjs(`${x.timestamp}`).format('MM/DD/YYYY HH:mm'));
      Object.entries(x.entries).forEach(([name, value]) => {
        let serie = extractedCustomSeries[name];
        if (!serie) {
          serie = {
            name,
            data: [],
            type: 'line',
            label: {
              show: true,
            },
            emphasis: {
              focus: 'series',
            },
          };
        }
        serie.data.push(parseFloat(value.toFixed(2)));
        extractedCustomSeries[name] = serie;
      });
    });

    const series: Serie[] = [];
    Object.entries(extractedCustomSeries).forEach(([_property, serie]) => {
      series.push(serie);
    });

    chartRef.current?.getEchartsInstance().setOption({
      grid: {
        top: 100,
      },
      xAxis: {
        type: 'category',
        data: timeSeries,
      },
      yAxis: {},
      series,
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: series.map((item) => item.name),
        formatter(name: any) {
          return `${name}`;
        },
        top: 20,
        type: 'scroll',
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
        },
        {
          type: 'inside',
          realtime: true,
        },
      ],
    });
  }

  extractCustomAnalyticSeries();

  return <ReactECharts option={emptyOptions} ref={chartRef} />;
}

export default CustomAnalytics;
