export default {
  styleOverrides: {
    html: {
      fontSize: '62.5%',
      height: '100%',
    },
    body: {
      height: '100%',
    },
    '#root': {
      height: '100%',
    },
  },
};
