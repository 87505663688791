export const isObject = <T>(obj: T) =>
  typeof obj === 'object' && obj !== null && !Array.isArray(obj);

export const deepMergeObjects = <
  T1 extends Record<string, unknown>,
  T2 extends Record<string, unknown>,
>(
  arg1?: T1,
  arg2?: T2,
): T1 & T2 => {
  const obj1 = (arg1 || {}) as T1;
  const obj2 = (arg2 || {}) as T2;
  const keys = [...Object.keys(obj1), ...Object.keys(obj2)];
  return keys.reduce(
    (acc, key) => {
      const val1 = obj1[key] as T1;
      const val2 = obj2[key] as T2;
      let resValue;
      if (isObject(val1) && isObject(val2)) {
        resValue = deepMergeObjects(val1, val2);
      } else if (Array.isArray(val1) && Array.isArray(val2)) {
        resValue = [...val1, ...val2];
      } else if (val2) {
        resValue = val2;
      } else {
        resValue = val1;
      }
      return { ...acc, [key]: resValue };
    },
    {} as T1 & T2,
  );
};

export const filterObject = <T>(
  object: Record<string, T>,
  predicate = (value: T) => value,
) =>
  Object.entries(object)
    .filter(([key, value]) => key && predicate(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const filterObjectByKeys = <T>(
  object: Record<string, T>,
  keys: string[],
): Record<string, T> =>
  Object.entries(object).reduce(
    (acc, [key, value]) =>
      keys.includes(key) ? { ...acc, [key]: value } : { ...acc },
    {},
  );

export const getObjFieldByPath = <T extends object, R>(
  obj: T,
  path: string,
) => {
  const keys = path.split('.');
  const result = keys.reduce(
    (nestedObj, key) => (nestedObj as T)?.[key as keyof T] as T | R,
    obj as T | R,
  );
  return result as R;
};

export const mapObject = <T, R>(
  obj: Record<string, T>,
  fn: (key: string, value: T) => R,
) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, fn(key, value)]),
  );

export const mapObjectToRecArr = <T>(
  obj: Record<string, T>,
  nameKey = 'name',
  valueKey = 'value',
) =>
  Object.entries(obj).map(([name, value]) => ({
    [nameKey]: name,
    [valueKey]: value,
  }));

export const setObjProperty = <T extends Record<string, unknown>, V>(
  obj: T,
  path: string,
  value: V,
): T => {
  const keys = path.split('.');
  const newObj = { ...obj };
  let currObj = newObj;
  keys.slice().forEach((key: keyof T, index: number) => {
    if (!currObj[key]) {
      currObj[key] = {} as T[keyof T];
    }
    if (index === keys.length - 1) {
      currObj[key] = value as T[keyof T];
    }
    currObj = currObj[key] as T;
  });
  return newObj;
};
