import moment from 'moment';
import LabeledBox from 'src/components/LabeledBox';
import Loader from 'src/components/Loader';
import { useGetDashboardStatusQuery } from 'src/backend';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import DeviceUnknownOutlinedIcon from '@mui/icons-material/DeviceUnknownOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import { Tooltip } from '@mui/material';
import { useEffect } from 'react';

function StatusInfo() {
  const since = moment().startOf('day').toISOString();
  const { data, isLoading, isFetching, refetch } =
    useGetDashboardStatusQuery(since);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 300000);
    return () => clearInterval(interval);
  }, [refetch]);

  return (
    <div style={{ margin: '10px' }}>
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <>
          <LabeledBox text="Devices" margin="0 5px 0 0">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                padding: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                }}
              >
                <Tooltip title="Online">
                  <WifiOutlinedIcon
                    sx={{ marginRight: '5px', color: '#92CE50' }}
                  />
                </Tooltip>
                <span>{data?.onlineStatusCount}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                }}
              >
                <Tooltip title="Offline">
                  <WifiOffOutlinedIcon
                    sx={{ marginRight: '5px', color: '#CE5050' }}
                  />
                </Tooltip>
                <span>{data?.offlineStatusCount}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                }}
              >
                <Tooltip title="Unknown">
                  <DeviceUnknownOutlinedIcon sx={{ marginRight: '5px' }} />
                </Tooltip>
                <span>{data?.unknownStatusCount}</span>
              </div>
            </div>
          </LabeledBox>
          <LabeledBox text="Tickets (total)" margin="0 5px 0 0">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                padding: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                }}
              >
                <Tooltip title="Open">
                  <RadioButtonUncheckedOutlinedIcon
                    sx={{ marginRight: '5px', color: '#CE5050' }}
                  />
                </Tooltip>
                <span>{data?.openTicketsCount}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                }}
              >
                <Tooltip title="Completed">
                  <TaskAltOutlinedIcon
                    sx={{ marginRight: '5px', color: '#92CE50' }}
                  />
                </Tooltip>
                <span>{data?.completedTicketsCount}</span>
              </div>
            </div>
          </LabeledBox>
          <LabeledBox text="Messages (today)">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                padding: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginRight: '10px',
                }}
              >
                <Tooltip title="Telemetry">
                  <MailOutlineOutlinedIcon sx={{ marginRight: '5px' }} />
                </Tooltip>
                <span>{data?.telemetryMessagesCount}</span>
              </div>
            </div>
          </LabeledBox>
        </>
      )}
    </div>
  );
}

export default StatusInfo;
