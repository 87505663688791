import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import HeaderUserMenu from './HeaderUserMenu';
import AppLogo from '../AppLogo';
import AppVersionBanner from '../AppVersionBanner';

function Header() {
  return (
    <AppBar>
      <Toolbar>
        <AppLogo />
        <Stack direction="row" alignItems="center" gap={1}>
          <AppVersionBanner />
          <HeaderUserMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
