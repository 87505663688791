import { ReactNode, useMemo } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { ProviderState, Providers } from '@microsoft/mgt-element';
import { msalInstance } from './config';
import AuthTemplate from './AuthTemplate';

type Props = {
  children: ReactNode;
};

function AuthProvider({ children }: Props) {
  useMemo(() => {
    const callbackId = msalInstance.addEventCallback((message) => {
      if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        Providers.globalProvider.setState(ProviderState.SignedIn);

        const [account] = msalInstance.getAllAccounts();
        if (account) {
          msalInstance.setActiveAccount(account);
        }
      }
      return () => {
        if (callbackId) {
          msalInstance.removeEventCallback(callbackId);
        }
      };
    });
  }, []);
  Providers.globalProvider.setState(ProviderState.SignedIn);

  const [account] = msalInstance.getAllAccounts();
  if (account) {
    msalInstance.setActiveAccount(account);
  }
  return (
    <MsalProvider instance={msalInstance}>
      <AuthTemplate>{children}</AuthTemplate>
    </MsalProvider>
  );
}

export default AuthProvider;
