import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/helpers';
import { generatePath } from 'react-router-dom';
import {
  CommandResult,
  CommandsSummary,
  Cursor,
  DashboardStatus,
  DeviceAlarm,
  DeviceAnalytics,
  DeviceCustomAnalytics,
  DeviceDetails,
  DeviceEntry,
  RoleAssignment,
  StatusCount,
  UserProfile,
} from './types';

export const backendApi = createApi({
  reducerPath: 'backendApi',
  baseQuery: baseQuery(),
  endpoints: ({ query, mutation }) => ({
    listDevices: query<
      Cursor<DeviceEntry[]>,
      { cursor: string | undefined; filter: string | undefined }
    >({
      query: ({ cursor, filter }) => ({
        url: '/device',
        method: 'GET',
        queryParams: { cursor, filter },
      }),
      transformResponse: (res: {
        data: DeviceEntry[];
        cursor?: { next?: string };
      }) => ({ data: res.data, cursor: res.cursor?.next }),
    }),
    getDeviceDetails: query<DeviceDetails, string>({
      query: (id) => ({
        url: generatePath('/device/:id', { id }),
        method: 'GET',
      }),
      transformResponse: (res: { data: DeviceDetails }) => res.data,
    }),
    getDeviceAlarms: query<DeviceAlarm[], string>({
      query: (id) => ({
        url: generatePath('/device/:id/alarms', { id }),
        method: 'GET',
      }),
      transformResponse: (res: { data: DeviceAlarm[] }) => res.data,
    }),
    getDashboardStatus: query<DashboardStatus, string>({
      query: (since) => ({
        url: generatePath('/dashboardStatus'),
        queryParams: { since },
        method: 'GET',
      }),
      transformResponse: (res: { data: DashboardStatus }) => res.data,
    }),
    getCommands: query<
      CommandsSummary,
      {
        cursor: string | undefined;
        registeredAfter: string | undefined;
        registeredBefore: string | undefined;
        serialNumbers: string | undefined;
        statuses: string | undefined;
      }
    >({
      query: ({
        cursor,
        registeredAfter,
        registeredBefore,
        serialNumbers,
        statuses,
      }) => ({
        url: '/commands',
        method: 'GET',
        queryParams: {
          cursor,
          registeredAfter,
          registeredBefore,
          serialNumbers,
          statuses,
        },
      }),
      transformResponse: (res: {
        data: CommandResult[];
        cursor?: { next?: string };
        statusesCount: StatusCount[];
      }) => ({
        data: res.data,
        cursor: res.cursor?.next,
        statusesCount: res.statusesCount,
      }),
    }),
    listRoleAssignments: query({
      query: () => ({
        url: '/roleAssignments',
        method: 'GET',
      }),
      transformResponse: (res: { data: RoleAssignment[] }) => ({
        data: res.data,
      }),
    }),
    getUserProfile: query({
      query: () => ({
        url: '/userProfile/me',
        method: 'GET',
      }),
      transformResponse: (res: { data: UserProfile }) => ({
        data: res.data,
      }),
    }),
    refreshConfiguration: mutation({
      query: (id) => ({
        url: generatePath('/device/:id/refreshConfiguration', { id }),
        method: 'POST',
      }),
      transformResponse: (res: { data: string }) => res.data,
    }),
    updateConfiguration: mutation({
      query: ({ id, configurationData }) => ({
        url: generatePath('/device/:id/updateConfiguration', { id }),
        method: 'POST',
        body: configurationData,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: string }) => res.data,
    }),
    getAnalytics: mutation({
      query: ({ id, analyticsDataRequest }) => ({
        url: generatePath('/device/:id/analyticsData', { id }),
        method: 'POST',
        body: analyticsDataRequest,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: DeviceAnalytics }) => res.data,
    }),
    getCustomAnalytics: mutation({
      query: ({ id, customAnalyticsDataRequest }) => ({
        url: generatePath('/device/:id/customAnalyticsData', { id }),
        method: 'POST',
        body: customAnalyticsDataRequest,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: DeviceCustomAnalytics }) => res.data,
    }),
    createRoleAssignment: mutation({
      query: ({ roleAssignment }) => ({
        url: '/roleAssignments',
        method: 'POST',
        body: roleAssignment,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: RoleAssignment }) => res.data,
    }),
    updateRoleAssignmentExpiration: mutation({
      query: ({ userId, role, roleAssignmentExpiration }) => ({
        url: generatePath('/roleAssignments/:userId/roles/:role', {
          userId,
          role,
        }),
        method: 'PUT',
        body: roleAssignmentExpiration,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: RoleAssignment }) => res.data,
    }),
    deleteRoleAssignment: mutation({
      query: ({ userId, role }) => ({
        url: generatePath('/roleAssignments/:userId/roles/:role', {
          userId,
          role,
        }),
        method: 'DELETE',
      }),
      transformResponse: (res: { data: RoleAssignment }) => res.data,
    }),
  }),
});

export const {
  useListDevicesQuery,
  useGetDeviceDetailsQuery,
  useGetDeviceAlarmsQuery,
  useGetDashboardStatusQuery,
  useGetCommandsQuery,
  useLazyGetUserProfileQuery,
  useListRoleAssignmentsQuery,
  useRefreshConfigurationMutation,
  useUpdateConfigurationMutation,
  useGetAnalyticsMutation,
  useGetCustomAnalyticsMutation,
  useCreateRoleAssignmentMutation,
  useUpdateRoleAssignmentExpirationMutation,
  useDeleteRoleAssignmentMutation,
} = backendApi;
