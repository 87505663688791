import Grid from '@mui/material/Grid';
import PageTitle from 'src/components/PageTitle';
import DashboardDevices from './DashboardDevices';

function Dashboard() {
  return (
    <>
      <PageTitle />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardDevices />
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
