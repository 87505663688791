import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';

const statusMap = new Map([
  [true, { color: 'success', label: 'Online' }],
  [false, { color: 'error', label: 'Offline' }],
]);

function ConnectStatusBadge<TRow extends GridRowModel>({
  value,
}: Partial<GridRenderCellParams<TRow, boolean>>) {
  const props = statusMap.get(!!value) as ChipProps;
  return <Chip {...props} />;
}

export default ConnectStatusBadge;
