import ConnectStatusBadge from 'src/components/ConnectStatusBadge';
import { DeviceItemField, DeviceType } from './types';

export const devicesComponentsMap = new Map([
  [DeviceType.Gateway, ['devicehealth', 'wifi', 'cellular']],
  [DeviceType.Ams, ['sensormanager']],
  [DeviceType.Es8, ['sensormanager']],
]);

export const generalDevicesCols = [
  {
    name: DeviceItemField.Connected,
    renderCell: ConnectStatusBadge,
  },
];
