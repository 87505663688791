export type DataTelemetrySnr = {
  cfgV: number;
  fwV: number;
  rssi: number;
  ts: string;
  ing?: boolean;
  egr?: boolean;
  upd?: boolean;
};

export type DataTelemetry = {
  batteryLevel?: number;
  RSSI?: number;
  hp?: number;
  rt?: number;
  wu?: string;
  snr?: Record<string, DataTelemetrySnr>;
};

export type DataResponseItem = {
  applicationId: string;
  component: string;
  enqueuedTime: string;
  enrichments: unknown;
  gatewayId: string;
  messageSource: string;
  organizationId: string;
  organizationPath: string;
  schema: string;
  telemetry: DataTelemetry;
  templateId: string;
};

export enum EventItemField {
  Type = 'type',
  Description = 'description',
  DeviceId = 'deviceId',
  EnqueuedTime = 'enqueuedTime',
  Severity = 'severity',
}

export enum EventType {
  Connect = 'connect',
  Egress = 'Egress',
  Ingress = 'ingress',
  Update = 'Update',
}

export enum EventSeverity {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export type EventItem = DataResponseItem & {
  id: string;
  severity?: EventSeverity;
  type?: EventType | null;
  description?: EventType | null;
};

export type EventsSummary = Partial<Record<EventSeverity, number>>;

export type EventsData = {
  items: EventItem[];
  summary: EventsSummary;
};

export enum TelemetryMetricField {
  Label = 'label',
  Last = 'last',
  EnqueuedTime = 'enqueuedTime',
}

export type TelemetrySummaryItem = {
  id: string;
  enqueuedTime?: string;
  label?: string;
  last?: number;
};

export type TelemetryLatest = Record<string, Partial<TelemetrySummaryItem>>;

export type TelemetrySelection = Record<string, boolean>;

export type TelemetryChartEvent = {
  name: string;
  selected: TelemetrySelection;
  type: string;
};

export type TelemetryChartPoint = {
  name: string;
  value: [string?, number?];
};

export type TelemetryChartPoints = Record<string, TelemetryChartPoint[]>;

export type TelemetryData = {
  summary: TelemetrySummaryItem[];
  points: TelemetryChartPoints;
};
