/* eslint-disable @typescript-eslint/no-explicit-any */
import { HighlightOffOutlined } from '@mui/icons-material';
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Input,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { ConfigurationData } from 'src/backend/types';
import { parseBool } from 'src/pages/DeviceDetails/utils';
import { useUpdateConfigurationMutation } from 'src/backend';
import { useParams } from 'react-router-dom';
import DataField from '../DataField/DataField';
import LoadingButton from '../LoadingButton';
import OnOffSwitch from '../OnOffSwitch/OnOffSwitch';

const inputStyle = {
  width: '6em',
  margin: '0 0 0 2em',
};

type RouteParams = {
  id: string;
};

type Params = {
  isOpen: boolean;
  configurationData: ConfigurationData;
  closeForm: (refreshData: boolean) => void;
};

function UpdateConfigurationForm({
  isOpen,
  configurationData,
  closeForm,
}: Params) {
  const [formData, setFormData] = useState(configurationData);
  const [updateConfiguration, { isLoading }] = useUpdateConfigurationMutation();
  const { id } = useParams<RouteParams>();

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: parseFloat(value),
    });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setFormData({
      ...formData,
      [name]: `${event.target.checked}`,
    });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    updateConfiguration({ id, configurationData: formData })
      .unwrap()
      .then(() => closeForm(true))
      .catch(() => closeForm(false));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeForm(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Update configuration
        <IconButton onClick={() => closeForm(false)} style={{ float: 'right' }}>
          <HighlightOffOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item xs={10}>
            <Grid container spacing={2} paddingBottom={1}>
              <Grid item xs={6}>
                <Divider />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Seal temperature
                </Typography>
                <DataField
                  label="Cut (0-200)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 200 }}
                      sx={inputStyle}
                      name="sealTempCut"
                      type="number"
                      value={formData.sealTempCut}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Bottom (0-200)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 200 }}
                      sx={inputStyle}
                      name="sealTempBottom"
                      type="number"
                      value={formData.sealTempBottom}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Top (0-200)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 200 }}
                      sx={inputStyle}
                      name="sealTempTop"
                      type="number"
                      value={formData.sealTempTop}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Edge (0-30)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 30 }}
                      sx={inputStyle}
                      name="sealTempEdge"
                      type="number"
                      value={formData.sealTempEdge}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />

                <Divider sx={{ paddingTop: '2em' }} />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Film tension
                </Typography>
                <DataField
                  label="Idle (0-255)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 255 }}
                      sx={inputStyle}
                      name="filmTensionIdle"
                      type="number"
                      value={formData.filmTensionIdle}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Stop (0-255)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 255 }}
                      sx={inputStyle}
                      name="filmTensionStop"
                      type="number"
                      value={formData.filmTensionStop}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Advance (0-255)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 255 }}
                      sx={inputStyle}
                      name="filmTensionAdvance"
                      type="number"
                      value={formData.filmTensionAdvance}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <Divider sx={{ paddingTop: '2em' }} />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Chemical
                </Typography>
                <DataField
                  label="Temperature (80-160)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 80, max: 160 }}
                      sx={inputStyle}
                      name="chemTemp"
                      type="number"
                      value={formData.chemTemp}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <Divider sx={{ paddingTop: '2em' }} />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Vent
                </Typography>
                <DataField
                  label="Length (0-50)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 50 }}
                      sx={inputStyle}
                      name="ventLength"
                      type="number"
                      value={formData.ventLength}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Position (0-255)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 255 }}
                      sx={inputStyle}
                      name="ventPosition"
                      type="number"
                      value={formData.ventPosition}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <Divider sx={{ paddingTop: '2em' }} />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Flow
                </Typography>
                <DataField
                  label="Flow rate (10-50)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 10, max: 50 }}
                      sx={inputStyle}
                      name="foamFlowRate"
                      type="number"
                      value={formData.foamFlowRate}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Ratio A:B (50-150)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 50, max: 150 }}
                      sx={inputStyle}
                      name="foamRatioAb"
                      type="number"
                      value={formData.foamRatioAb}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Divider />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Foam
                </Typography>
                <DataField
                  label="Fill Factor (10-50)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 10, max: 50 }}
                      sx={inputStyle}
                      name="foamFillFactor"
                      type="number"
                      value={formData.foamFillFactor}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Stop Position (0-255)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 255 }}
                      sx={inputStyle}
                      name="stopPosition"
                      type="number"
                      value={formData.stopPosition}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <Divider sx={{ paddingTop: '2em' }} />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Bag sequence delay
                </Typography>
                <DataField
                  label="Film Deploy (0.02-1.0)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0.02, max: 1 }}
                      sx={inputStyle}
                      name="bagSequenceDelayFilmDeploy"
                      type="number"
                      value={formData.bagSequenceDelayFilmDeploy}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Bag Delay (0-1.5)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 1.5 }}
                      sx={inputStyle}
                      name="bagSequenceDelayBagDelay"
                      type="number"
                      value={formData.bagSequenceDelayBagDelay}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <DataField
                  label="Foam Drip (0-1.0)"
                  value={
                    <Input
                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                      sx={inputStyle}
                      name="bagSequenceDelayFoamDrip"
                      type="number"
                      value={formData.bagSequenceDelayFoamDrip}
                      onChange={(e) => handleInputChange(e)}
                    />
                  }
                />
                <Divider sx={{ paddingTop: '2em' }} />
                <Typography variant="body2" sx={{ paddingBottom: '1em' }}>
                  Sensors Data
                </Typography>
                <DataField
                  label="Dispancer Photo Eye"
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                      <OnOffSwitch
                        checked={parseBool(formData.dispancerPhotoEye)}
                        name="dispancerPhotoEye"
                        onChange={(e) => handleSwitchChange(e)}
                        inputProps={{ 'aria-label': 'ant design' }}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  }
                />
                <DataField
                  label="Jaw Photo Eyes"
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                      <OnOffSwitch
                        name="jawPhotoEyes"
                        checked={parseBool(formData.jawPhotoEyes)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  }
                />
                <DataField
                  label="Flip Door Switch"
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                      <OnOffSwitch
                        name="flipDoorSwitch"
                        checked={parseBool(formData.flipDoorSwitch)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  }
                />
                <DataField
                  label="Film Feed at Startup"
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                      <OnOffSwitch
                        name="filmFeedAtStartup"
                        checked={parseBool(formData.filmFeedAtStartup)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  }
                />
                <DataField
                  label="Main Cover Switch"
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                      <OnOffSwitch
                        name="mainCoverSwitch"
                        checked={parseBool(formData.mainCoverSwitch)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  }
                />
                <DataField
                  label="Film Spindle Switch"
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                      <OnOffSwitch
                        name="filmSpindleSwitch"
                        checked={parseBool(formData.filmSpindleSwitch)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  }
                />
                <DataField
                  label="Film Level Sense"
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                      <OnOffSwitch
                        name="filmLevelSense"
                        checked={parseBool(formData.filmLevelSense)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  }
                />
                <Container style={{ marginTop: '11em', alignItems: 'end' }}>
                  <LoadingButton
                    loading={isLoading}
                    variant="outlined"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateConfigurationForm;
