import { alpha } from '@mui/system/colorManipulator';
import { baseTheme } from '../baseTheme';

const { palette, spacing } = baseTheme;

export default {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      borderRadius: 5,
    },
    sizeSmall: {
      paddingInline: spacing(0.5),
    },
    colorDefault: {
      backgroundColor: alpha(palette.secondary.light, 0.5),
      color: palette.secondary.dark,
    },
    colorSuccess: {
      backgroundColor: alpha(palette.success.light, 0.5),
      color: palette.success.main,
    },
    colorWarning: {
      backgroundColor: alpha(palette.warning.light, 0.5),
      color: palette.warning.dark,
    },
    colorError: {
      backgroundColor: alpha(palette.error.light, 0.5),
      color: palette.error.main,
    },
  },
};
