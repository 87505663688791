import React from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const style: StackProps = {
  color: 'primary.main',
  direction: 'row',
  height: '100%',
  margin: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
};

function Loader({ children, ...rest }: StackProps) {
  return (
    <Stack {...style} {...rest}>
      <CircularProgress />
      {children}
    </Stack>
  );
}

export default Loader;
