/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnalyticsData } from 'src/backend';
import ReactECharts from 'echarts-for-react';
import { useRef } from 'react';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';

type Params = {
  analyticsData: AnalyticsData[];
};

function BasicAnalytics({ analyticsData }: Params) {
  const emptyOptions = {
    grid: {
      top: 100,
    },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {},
    series: [],
  };
  const chemChartRef = useRef(null as any);
  const psiChartRef = useRef(null as any);
  const filmChartRef = useRef(null as any);
  function extractAnalyticSeries() {
    const chemSeries: {
      name: string;
      data: number[];
      type: 'line';
      label: {
        show: true;
      };
      emphasis: {
        focus: 'series';
      };
    }[] = [];

    const psiSeries: {
      name: string;
      data: number[];
      type: 'line';
      label: {
        show: true;
      };
      emphasis: {
        focus: 'series';
      };
    }[] = [];

    const filmSeries: {
      name: string;
      data: number[];
      type: 'line';
      label: {
        show: true;
      };
      emphasis: {
        focus: 'series';
      };
    }[] = [];

    const timeSeries: string[] = [];
    const chemAData: number[] = [];
    const chemBData: number[] = [];
    const psiAData: number[] = [];
    const psiBData: number[] = [];
    const filmUsageData: number[] = [];

    analyticsData.forEach((x) => {
      timeSeries.push(dayjs(`${x.timestamp}`).format('MM/DD/YYYY HH:mm'));
      chemAData.push(parseFloat(x.chemA.toFixed(2)));
      chemBData.push(parseFloat(x.chemB.toFixed(2)));
      psiAData.push(parseFloat(x.psiA.toFixed(2)));
      psiBData.push(parseFloat(x.psiB.toFixed(2)));
      filmUsageData.push(parseFloat(x.filmUsage.toFixed(2)));
    });

    chemSeries.push({
      name: 'A Component',
      data: chemAData,
      type: 'line',
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
    });
    chemSeries.push({
      name: 'B Component',
      data: chemBData,
      type: 'line',
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
    });

    const chemATotal = analyticsData
      .reduce((total, n) => total + n.chemA, 0)
      .toFixed(2);
    const chemBTotal = analyticsData
      .reduce((total, n) => total + n.chemB, 0)
      .toFixed(2);
    chemChartRef.current?.getEchartsInstance().setOption({
      title: {
        text: 'A & B Component Consumption (lbs)',
        subtext: `A total: ${chemATotal} lbs\nB total: ${chemBTotal} lbs`,
        left: 'center',
        top: 10,
      },
      grid: {
        top: 130,
        height: 130,
      },
      xAxis: {
        type: 'category',
        data: timeSeries,
      },
      yAxis: {},
      series: chemSeries,
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: chemSeries.map((item) => item.name),
        padding: [5, 5, 5, 5],
        selectedMode: false,
        top: 80,
      },
    });

    psiSeries.push({
      name: 'A Pressure',
      data: psiAData,
      type: 'line',
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
    });
    psiSeries.push({
      name: 'B Pressure',
      data: psiBData,
      type: 'line',
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
    });

    psiChartRef.current?.getEchartsInstance().setOption({
      title: {
        text: 'A & B Average Pressure (PSI)',
        left: 'center',
        top: 10,
      },
      grid: {
        top: 100,
      },
      xAxis: {
        type: 'category',
        data: timeSeries,
      },
      yAxis: {},
      series: psiSeries,
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: psiSeries.map((item) => item.name),
        selectedMode: false,
        top: 50,
      },
    });

    const filmTotal = analyticsData
      .reduce((total, n) => total + n.filmUsage, 0)
      .toFixed(2);
    filmSeries.push({
      name: 'Film Usage',
      data: filmUsageData,
      type: 'line',
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
    });

    filmChartRef.current?.getEchartsInstance().setOption({
      title: {
        text: 'Film Consumption (in)',
        subtext: `Film total: ${filmTotal} in`,
        left: 'center',
        top: 10,
      },
      grid: {
        top: 100,
      },
      xAxis: {
        type: 'category',
        data: timeSeries,
      },
      yAxis: {},
      series: filmSeries,
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
    });
  }

  extractAnalyticSeries();

  return (
    <>
      <ReactECharts option={emptyOptions} ref={chemChartRef} />
      <Divider />
      <ReactECharts option={emptyOptions} ref={psiChartRef} />
      <Divider />
      <ReactECharts option={emptyOptions} ref={filmChartRef} />
    </>
  );
}

export default BasicAnalytics;
