import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'azure-maps-control/dist/atlas.min.css';
import 'src/theme/fonts';
import { Providers, SimpleProvider } from '@microsoft/mgt-element';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import App from './App';
import { msalInstance } from './entities/auth/config';

Providers.globalProvider = new SimpleProvider((_scopes: string[]) => {
  const request = {
    scopes: ['user.read', 'people.read.all', 'user.readbasic.all'],
  };
  return msalInstance.acquireTokenSilent(request).then((tokenResponse) =>
    new Promise<string>((resolve) => {
      resolve(tokenResponse.accessToken);
    }).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenPopup(request).then(
          (resp) =>
            new Promise<string>((resolve) => {
              resolve(resp.accessToken);
            }),
        );
      }
      return new Promise<string>((resolve) => {
        resolve('');
      });
    }),
  );
});

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
