import { useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Theme } from '@mui/material/styles/createTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { getNavRoutes } from 'src/router';
import SidebarNavLink from './SidebarNavLink';
import { SIDEBAR_CLOSED_SIZE, SIDEBAR_OPEN_SIZE } from './constants';
import SidebarFooter from './SidebarFooter';
import RoleBased from '../RoleBased';

function Sidebar() {
  const isDesktop = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.up('md'),
  );
  const [isOpen, setIsOpen] = useState(false);
  const width = isOpen && isDesktop ? SIDEBAR_OPEN_SIZE : SIDEBAR_CLOSED_SIZE;
  const { pathname } = useLocation();

  return (
    <Drawer sx={{ width }}>
      <List>
        {getNavRoutes().map(({ path = '', meta }) => {
          const selected = !!matchPath({ path, end: false }, pathname);
          return (
            <RoleBased
              key={path}
              requiredRole={meta?.accessRole}
              component={
                <SidebarNavLink
                  path={path}
                  selected={selected}
                  open={isOpen && isDesktop}
                  {...meta}
                />
              }
            />
          );
        })}
      </List>
      {false && isDesktop && (
        <SidebarFooter open={isOpen} toggleOpen={() => setIsOpen(!isOpen)} />
      )}
    </Drawer>
  );
}

export default Sidebar;
