import { Box, Tooltip, IconButton, Modal, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import PageCard from 'src/components/PageCard';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import LoadingIconButton from 'src/components/LoadingIconButton';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DataGrid,
  FilterColumnsArgs,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { CommandResult, useGetCommandsQuery } from 'src/backend';
import { JSONTree } from 'react-json-tree';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LabeledBox from 'src/components/LabeledBox';
import StatusSummary from 'src/components/StatusSummary';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CommandsSearchBar from './CommandsSearchBar';

dayjs.extend(utc);

function CommandDiagnostics() {
  const initialAfterDate = dayjs().subtract(1, 'd');
  const initialBeforeDate = dayjs();

  const columns: GridColDef<CommandResult>[] = [
    {
      field: 'id',
      headerName: 'Twin ID',
      sortable: false,
      flex: 1,
    },
    {
      field: 'registeredOn',
      headerName: 'Registered on [UTC]',
      sortable: false,
      valueFormatter: (v) => dayjs.utc(v.value).format('MM-DD-YYYY HH:mm A'),
      flex: 1,
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number  ',
      sortable: false,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      renderCell: (i) => <StatusSummary status={i.value} showTooltip />,
    },
    {
      field: 'commandName',
      headerName: 'Command',
      sortable: false,
      flex: 2,
    },
  ];
  const theme = {
    base00: 'white',
    base0D: 'black',
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const columnVisibilityModel: GridColumnVisibilityModel = {
    id: false,
  };
  const [selectedRow, setSelectedRow] = useState<CommandResult | undefined>(
    undefined,
  );
  const [pageState, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const mapPageToCursor = useRef<Map<number, string | undefined>>(
    new Map<number, string>(),
  );

  const [registeredAfter, setRegisteredAfter] = useState<string | undefined>(
    initialAfterDate.toString(),
  );
  const [registeredBefore, setRegisteredBefore] = useState<string | undefined>(
    initialBeforeDate.toString(),
  );
  const [serialNumbers, setSerialNumbers] = useState<string | undefined>(
    undefined,
  );
  const [statuses, setStatuses] = useState<string | undefined>(undefined);

  const cursor = mapPageToCursor.current.get(pageState.page);
  const { data, isLoading, isFetching, refetch } = useGetCommandsQuery({
    cursor,
    registeredAfter,
    registeredBefore,
    serialNumbers,
    statuses,
  });

  const showCommandPayload = useCallback(
    (id: string) => {
      const cmd = data?.data.find((x) => x.id === id);
      setSelectedRow(cmd);
    },
    [data?.data, setSelectedRow],
  );

  const [refetchRequired, setRefetchRequired] = useState(false);

  const onPaginationModelChange = (m: GridPaginationModel) => {
    if (m.page !== pageState.page) {
      setSelectedRow(undefined);
      setPaginationModel({
        page: m.page,
        pageSize: pageState.pageSize,
      });
    }
  };

  const filterColumns = ({ columns }: FilterColumnsArgs) =>
    columns.map((column) => column.field);

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
    }
  }, [refetchRequired, refetch]);

  useEffect(() => {
    if (!isLoading && data?.cursor) {
      mapPageToCursor.current.set(pageState.page + 1, data?.cursor);
    }
  }, [data?.cursor, isLoading, pageState.page]);

  const [rowCountState, setRowCountState] = useState({
    rowCount: 10,
    totalRowCount: -1,
    topPage: -1,
    isFinal: false,
  });

  useEffect(() => {
    if (
      !rowCountState.isFinal &&
      !isLoading &&
      !isFetching &&
      pageState.page >= rowCountState.topPage
    ) {
      const previousPagesCount = pageState.page * pageState.pageSize;
      const currentPageCount = data?.data.length ?? 0;
      const nextPage = data?.cursor ? 1 : 0;
      const rowCount = previousPagesCount + currentPageCount + nextPage;
      const totalRowCount = data?.statusesCount.reduce(
        (total, s) => total + s.count,
        0,
      );

      setRowCountState({
        isFinal: !nextPage,
        rowCount,
        totalRowCount: totalRowCount ?? -1,
        topPage: pageState.page,
      });
    }
  }, [
    rowCountState.isFinal,
    rowCountState.topPage,
    isLoading,
    isFetching,
    pageState.page,
    pageState.pageSize,
    data?.data.length,
    data?.cursor,
    data?.statusesCount,
  ]);

  const resetPaging = useCallback(() => {
    mapPageToCursor.current = new Map<number, string | undefined>();
    setSelectedRow(undefined);
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setRowCountState({
      rowCount: 10,
      totalRowCount: rowCountState.totalRowCount,
      topPage: -1,
      isFinal: false,
    });
  }, [rowCountState.totalRowCount]);

  const setFilteringOptions = useCallback(
    (
      afterDate: dayjs.Dayjs,
      beforeDate: dayjs.Dayjs,
      serialNumbers: string,
      statuses: string,
    ) => {
      resetPaging();
      setRegisteredAfter(afterDate.toString());
      setRegisteredBefore(beforeDate.toString());
      setSerialNumbers(serialNumbers);
      setStatuses(statuses);
    },
    [resetPaging],
  );
  const [showModal, setShowModal] = useState(false);
  const statusInfo: { [key: string]: string } = {
    Scheduled: 'command registered in system',
    Pending: 'command sent to Smart Bagger',
    Processed: 'command response received from Smart Bagger',
    Acknowledged: 'command acknowledgement has been sent',
    NotAcknowledged: 'command acknowledgement error',
  };

  return (
    <PageCard
      title="Command list"
      icon={<TableRowsOutlinedIcon />}
      contentSx={{ display: 'block' }}
      sx={{
        '& .MuiCardHeader-action': {
          width: '80em',
          display: 'flex',
          marginLeft: 'auto',
          marginRight: '0',
        },
      }}
      action={
        <>
          <div style={{ width: '95em', paddingTop: '10px', display: 'flex' }}>
            {data?.statusesCount.map(({ status, count }) => (
              <StatusSummary
                key={status}
                status={status}
                count={count}
                showTooltip
              />
            ))}
          </div>
          <IconButton onClick={() => setShowModal(true)}>
            <Tooltip title="Statuses Info">
              <InfoOutlinedIcon fontSize="large" />
            </Tooltip>
          </IconButton>
          <LoadingIconButton
            loading={isLoading || isFetching}
            onClick={() => setRefetchRequired(true)}
          >
            <Tooltip title="Refresh">
              <CachedOutlinedIcon fontSize="large" />
            </Tooltip>
          </LoadingIconButton>
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Statuses info
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {Object.entries(statusInfo).map(
                  ([status, description], _index) => (
                    <div
                      key={crypto.randomUUID()}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: '10px',
                      }}
                    >
                      <StatusSummary status={status} showTooltip={false} />
                      <span>
                        {status} - {description}
                      </span>
                    </div>
                  ),
                )}
              </Typography>
            </Box>
          </Modal>
        </>
      }
    >
      <CommandsSearchBar
        initialAfterDate={initialAfterDate}
        initialBeforeDate={initialBeforeDate}
        setFilteringOptions={(afterDate, beforeDate, serialNumbers, statuses) =>
          setFilteringOptions(afterDate, beforeDate, serialNumbers, statuses)
        }
      />
      <div style={{ display: 'flex' }}>
        <Grid item xs={6}>
          <DataGrid
            classes={{ row: 'selectable' }}
            columns={columns}
            rows={data?.data ?? []}
            loading={isLoading || isFetching}
            columnVisibilityModel={columnVisibilityModel}
            onRowClick={({ id }) => showCommandPayload(id.toString())}
            slotProps={{
              filterPanel: {
                filterFormProps: {
                  filterColumns,
                },
              },
              pagination: { count: rowCountState.totalRowCount },
            }}
            sx={{
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                display: 'none',
              },
              '& .MuiDataGrid-overlay': {
                height: '100%',
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rowCount={rowCountState.rowCount}
            paginationMode="server"
            filterMode="server"
            onPaginationModelChange={onPaginationModelChange}
            paginationModel={pageState}
            pageSizeOptions={[10]}
            hideFooterSelectedRowCount
          />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ padding: '30px 0 0 40px' }}>
            <LabeledBox
              text="Command trace"
              backgroundColor="white"
              width="100%"
            >
              {selectedRow ? (
                <JSONTree data={selectedRow} theme={theme} hideRoot />
              ) : (
                'Select command to see payload'
              )}
            </LabeledBox>
          </Box>
        </Grid>
      </div>
    </PageCard>
  );
}

export default CommandDiagnostics;
