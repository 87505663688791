import { v4 as uuidv4 } from 'uuid';
import {
  doubleJsonStr,
  getUniqArrItemsBy,
  groupArrayBy,
  mapObject,
  mapObjectToRecArr,
  sortArrayAscBy,
  splitArrayBy,
} from 'src/utils';
import { AppConfig, parseModel, transferToModel } from '../config';
import { DEVICES_STORE_LIST_TAG } from './constants';
import {
  DeviceCommandParams,
  DeviceCommandType,
  DeviceConnectData,
  DeviceItem,
  DeviceItemField,
  DeviceItemSensor,
  DeviceOverviewGridData,
  DeviceResponseItem,
  DevicesData,
  DeviceType,
} from './types';

export const mapDeviceItem = (
  item: DeviceResponseItem,
  config: AppConfig,
): DeviceItem => {
  const modelId = item?.$metadata.$model;
  const model = parseModel(config, item, modelId);
  return {
    id: item.$dtId,
    modelId,
    ...model,
    ...item,
  };
};

export const getRegisteredDevices = (
  data: DeviceResponseItem[],
  config: AppConfig,
): DeviceItem[] =>
  data
    // .filter(
    //   ({ contextTwins }) => contextTwins && Object.keys(contextTwins).length,
    // )
    .map((item) => mapDeviceItem(item, config));

export const mapDevicesResponse = (
  data: DeviceResponseItem[],
  config: AppConfig,
): DevicesData => {
  const items = sortArrayAscBy(
    getRegisteredDevices(data, config),
    DeviceItemField.Connected,
  );
  // eslint-disable-next-line no-console
  console.log('id1', config);
  const deviceTypes = getUniqArrItemsBy(items, ({ type }) => type).length;
  const { gateway = [] } = groupArrayBy(items, DeviceItemField.Type);
  return {
    items,
    summary: {
      totalDevices: items.length - gateway.length,
      totalGateways: gateway.length,
      deviceTypes,
    },
  };
};

export const mapDeviceOverviewGridRes = (
  data: DeviceResponseItem[],
  config: AppConfig,
): DeviceOverviewGridData => {
  const items = sortArrayAscBy(
    data.map((item) => mapDeviceItem(item, config)),
    DeviceItemField.Connected,
  );
  const [online, offline] = splitArrayBy(items, ({ connected }) => connected);
  return {
    items,
    summary: {
      total: items.length,
      online: online.length,
      offline: offline.length,
    },
  };
};

export const getDevicesConnectSummaryByType = (items?: DeviceItem[]) =>
  mapObject(groupArrayBy(items || [], DeviceItemField.Type), (_, group) => {
    const [online, offline] = splitArrayBy(group, ({ connected }) => connected);
    return [online.length, offline.length];
  });

export const getDeviceConnectByGatewayId = (
  data?: DeviceConnectData[],
  id?: string,
) => data?.find(({ gatewayId }) => id === gatewayId);

export const getDeviceCommandReq = ({
  id,
  type,
  action,
  modelId,
  ...params
}: DeviceCommandParams) => {
  const isGateway = type === DeviceType.Gateway;
  const isReboot = action === DeviceCommandType.Reboot;
  const isConfigUpdate = action === DeviceCommandType.SetDevice;
  const req = action.toLowerCase();
  const data = { ...params, cfgV: 1 };
  const cmdParams = isConfigUpdate ? { req, force: true, data } : { req };
  return isGateway
    ? {
        commandName: action,
        componentName: 'devicehealth',
        commandProperties: [isReboot ? { name: 'Wait', value: '60' } : {}],
      }
    : {
        commandName: 'sensorCmd',
        componentName: 'sensormanager',
        commandProperties: mapObjectToRecArr({
          cmd: doubleJsonStr(cmdParams),
          cmdId: `"${uuidv4()}"`,
          deviceId: `"B${id}"`,
          tmo: '60',
        }),
      };
};

export const getDeviceGatewayConfigReq = (
  { action, modelId, ...params }: DeviceCommandParams,
  config: AppConfig,
) => {
  const { sensormanager, ...data } = transferToModel(config, params, modelId);
  data.sensormanager = mapObjectToRecArr(sensormanager as DeviceItemSensor);
  return {
    componentProperties: mapObjectToRecArr(data, 'componentName', 'properties'),
  };
};

export const isValidDeviceType = (item?: DeviceItem) =>
  item && Object.values(DeviceType).includes(item.type);

export const isInvalidatingCommand = <R, E, T>(
  res: R,
  err: E,
  { action }: DeviceCommandParams,
): T[] =>
  ([DeviceCommandType.Wink, DeviceCommandType.Reboot].includes(action)
    ? []
    : [DEVICES_STORE_LIST_TAG]) as T[];
