import { Stack, SxProps, Typography } from '@mui/material';
import { DataFieldValue } from './types';

const rowStyle: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  wordBreak: 'break-word',
  paddingBottom: '0.5em',
};

type Params<T extends DataFieldValue> = {
  label: string;
  value?: T;
  defaultValue?: T;
  labelPadding?: string;
};

export default function DataField<T extends DataFieldValue>({
  label,
  value,
  defaultValue = '-',
  labelPadding = '',
}: Params<T>) {
  return (
    <Stack typography="body1" sx={rowStyle}>
      <Typography
        variant="body1"
        sx={{ pr: 1, minWidth: '30%', padding: labelPadding }}
      >
        {label}
      </Typography>
      <Typography variant="caption">{value ?? defaultValue}</Typography>
    </Stack>
  );
}
