import { ComponentType } from 'react';
import {
  DefaultValues,
  FieldValues,
  UseFormProps as UseHookFormProps,
} from 'react-hook-form';
import * as Yup from 'yup';
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs as RtkFetchArgs,
  FetchBaseQueryError,
  TypedUseQueryHookResult,
  TypedUseQueryStateResult,
} from '@reduxjs/toolkit/query/react';
import { QueryStringParams } from 'src/utils';

// API

export enum ReqMethod {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH',
  Put = 'PUT',
  Delete = 'DELETE',
}

export type ErrorDetails = {
  description: string;
  reason: string;
};

export type ErrorResponse = {
  errorDetails?: ErrorDetails;
  message?: string;
  status?: number;
  title?: string;
};

export type FetchArgs = Omit<RtkFetchArgs, 'url'> & {
  url?: string;
  queryParams?: QueryStringParams;
};

export type QueryReturnValue<T, E, M = unknown> =
  | { error: E; data?: undefined; meta?: M }
  | { error?: undefined; data: T; meta?: M };

export type QueryResult<T = unknown, E = undefined> =
  | QueryReturnValue<T, E>
  | PromiseLike<QueryReturnValue<T, E>>;

export type QueryFn<P = Record<string, unknown>, R = unknown, E = undefined> = (
  params: P,
  api: BaseQueryApi,
  extra: unknown,
  baseQuery: (params: FetchArgs) => QueryResult<unknown, FetchBaseQueryError>,
) => QueryResult<R, E>;

export type UseQueryResult<
  ResultType,
  QueryArg = unknown,
> = TypedUseQueryHookResult<ResultType, QueryArg, BaseQueryFn>;

export type UseQueryStateResult<
  ResultType,
  QueryArg = unknown,
> = TypedUseQueryStateResult<ResultType, QueryArg, BaseQueryFn>;

// FORM

export type Validation = Record<string, Yup.AnySchema>;

export type UseFormProps<TFieldValues extends FieldValues> = Omit<
  UseHookFormProps<TFieldValues>,
  'defaultValues'
> & {
  defaultValues?: DefaultValues<TFieldValues>;
  validation: Validation;
};

export enum FormLayoutConfigItemField {
  Group = 'group',
  Name = 'name',
}

export type FormLayoutConfigItem<T> = T & {
  name: string;
  group?: string;
  component?: ComponentType<T>;
  validation?: Yup.AnySchema;
};

export type FormLayoutParams<
  TComponent,
  TFieldValues extends FieldValues,
> = Omit<UseFormProps<TFieldValues>, 'validation'> & {
  config?: FormLayoutConfigItem<TComponent>[];
  defaultParams?: Partial<FormLayoutConfigItem<TComponent>>;
};
