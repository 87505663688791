import { useLocation, matchRoutes } from 'react-router-dom';
import { routes as rootRoutes } from './routes';
import { RouteMetaProps } from './types';

export const useMatchRoute = (root?: boolean) => {
  const location = useLocation();
  const routesMatch = matchRoutes(rootRoutes, location) || [];
  const routeMatch = root ? routesMatch[0] : routesMatch.pop();
  const { route } = routeMatch || {};
  return route;
};

export const useMatchRouteMeta = (root?: boolean) =>
  (useMatchRoute(root)?.meta || {}) as RouteMetaProps;
