import { useContext, useEffect } from 'react';
import { API_ERROR_EVENT, ErrorResponse } from 'src/helpers';
import AlertContext from './AlertContext';
import { AlertHandlerProps, AlertType } from './types';

export const useAlert = () => useContext(AlertContext);

export const useApiErrorAlert = (
  showAlert: (props: AlertHandlerProps) => void,
) => {
  const handleApiError = ({ detail }: CustomEvent) => {
    const { errorDetails, message, title } = (detail?.data ||
      {}) as ErrorResponse;
    const { description } = errorDetails || {};
    const text = title || message || description;
    if (text) {
      showAlert({ type: AlertType.Error, text });
    }
  };

  useEffect(() => {
    document.addEventListener(API_ERROR_EVENT, handleApiError as EventListener);
    return () => {
      document.removeEventListener(
        API_ERROR_EVENT,
        handleApiError as EventListener,
      );
    };
  });
};
