import { Checkbox, ListItem, ListItemIcon } from '@mui/material';
import { useCallback, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

type Params = {
  isOpen: boolean;
  closeDialog: (selectedProperties: string[]) => void;
};

function MeasuredValuesDialog({ isOpen, closeDialog }: Params) {
  const telemetryProperties = [
    'sb_shot_duration',
    'sb_lifetime_shot_count',
    'sb_shots_since_reset',
    'sb_film_tension_power',
    'sb_film_ratio',
    'sb_film_tach',
    'sb_flow_solvent',
    'sb_psi_s',
    'sb_psi_a',
    'sb_psi_b',
    'sb_seal_bottom',
    'sb_seal_top',
    'sb_cross_cut_wire',
    'sb_tach_a_pump',
    'sb_tach_b_pump',
    'sb_tach_dispenser',
    'sb_tach_spindle',
    'sb_temp_a_manifold',
    'sb_temp_b_hose',
    'sb_temp_a_hose',
    'sb_temp_b_manifold',
    'sb_temp_dispenser',
    'sb_temp_internal',
    'sb_tach_jaw',
    'sb_temp_reference',
    'sb_usage_a_chem',
    'sb_usage_b_chem',
  ];
  const configurationProperties = [
    'sb_seal_temp_cut_wire',
    'sb_seal_temp_top',
    'sb_seal_temp_bottom',
    'sb_seal_temp_edge',
    'sb_film_tsn_idle',
    'sb_film_tsn_advance',
    'sb_film_tsn_stop',
    'sb_vent_length',
    'sb_vent_pos',
    'sb_chemical_temp',
    'sb_foam_flow_rate',
    'sb_foam_ratio_a_b',
    'sb_stop_pos',
    'sb_bag_seq_delay_film_deploy',
    'sb_bag_seq_delay_foam_drip',
    'sb_bag_seq_delay_bag_delay',
  ];
  const [properties, setProperties] = useState(['sb_lifetime_shot_count']);

  const handleToggle = useCallback(
    (value: string) => {
      const wasSelected = properties.includes(value);
      const newProperties = [...properties];

      if (wasSelected) {
        const index = newProperties.indexOf(value, 0);
        if (index > -1) {
          newProperties.splice(index, 1);
        }
      } else {
        newProperties.push(value);
      }

      setProperties(newProperties);
    },
    [properties],
  );

  function getCategoryName(property: string): string {
    if (telemetryProperties.includes(property)) {
      return 'Telemetry Data';
    }

    return 'Configuration Data';
  }

  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      sx={{ '& .MuiDialogContent-root': { overflowY: 'auto' } }}
    >
      <DialogTitle>Select Measured Values</DialogTitle>
      <DialogContent dividers>
        <List sx={{ width: '30em' }}>
          {telemetryProperties.concat(configurationProperties).map((value) => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem key={value} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handleToggle(value)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={properties.includes(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${value}`}
                    secondary={getCategoryName(value)}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog(properties)}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MeasuredValuesDialog;
