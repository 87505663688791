/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useRef } from 'react';
import { ErrorData } from 'src/backend';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';

type Params = {
  errorData: ErrorData;
};

function ErrorAnalytics({ errorData }: Params) {
  const emptyOptions = {
    grid: {
      top: 100,
    },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {},
    series: [],
  };

  const errorsChartRef = useRef(null as any);
  const array: number[] = useMemo(() => [], []);

  const totalErrorsData: any[] = useMemo(
    () => [
      {
        name: ' A total of ',
        type: 'bar',
        tooltip: {
          show: false,
        },
        color: 'transparent',
        label: {
          show: true,
          position: 'top',
          textStyle: {
            fontWeight: 'bold',
          },
        },
        data: array,
        barGap: '-100%',
      },
    ],
    [array],
  );

  const sumOccurences = useCallback(
    (series: any) => {
      array.splice(0, array.length);
      if (series.length === 1) {
        return;
      }
      for (let z = 0; z < series.length - 1; z += 1) {
        for (let i = 0; i < series[z].data.length; i += 1) {
          if (z === 0) {
            array[i] = Number(series[z].data[i]);
          } else {
            array[i] += Number(series[z].data[i]);
          }
        }
      }
    },
    [array],
  );

  function extractErrorSeries() {
    const timeSeries: string[] = [];
    errorData.errorAnalytics.forEach((x) => {
      timeSeries.push(dayjs(`${x.timestamp}`).format('L'));
    });

    const series: {
      name: string;
      data: number[];
      type: 'bar';
      stack: 'x';
      label: {
        show: true;
        formatter: (p: { value: number }) => string;
      };
      emphasis: {
        focus: 'series';
      };
    }[] = [];

    const legend: string[] = [];

    Object.entries(errorData.errorCodes).forEach(([code, _description]) => {
      const seriesData: number[] = [];
      legend.push(code);

      errorData.errorAnalytics.forEach((errorAnalytic) => {
        seriesData.push(errorAnalytic[code] as number);
      });
      series.push({
        name: code,
        data: seriesData,
        type: 'bar',
        stack: 'x',
        label: {
          show: true,
          formatter: (p: { value: number }) =>
            p.value ? p.value.toString() : '',
        },
        emphasis: {
          focus: 'series',
        },
      });
    });

    const newSeries = series.concat(totalErrorsData);

    sumOccurences(newSeries);

    if (newSeries.length === 1) {
      newSeries.push({
        name: '',
        data: [0],
        type: 'bar',
        stack: 'x',
        label: {
          show: true,
          formatter: (p: { value: number }) =>
            p.value ? p.value.toString() : '',
        },
        emphasis: {
          focus: 'series',
        },
      });
    }

    errorsChartRef.current?.getEchartsInstance().setOption({
      title: {
        text: 'Error Codes',
        left: 'center',
        top: 10,
      },
      grid: {
        top: 130,
      },
      xAxis: {
        type: 'category',
        data: timeSeries,
      },
      yAxis: {},
      series: newSeries,
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            formatter(params: { seriesData: string | any; value: number }) {
              let result = 0;
              for (let i = 0; i < params.seriesData.length; i += 1) {
                result += params.seriesData[i].value;
              }
              const resultFinal = `${params.value} -------------------- total: ${result}`;
              result = 0;
              return resultFinal;
            },
          },
        },
      },
      legend: {
        data: series.map((item) => item.name),
        formatter(name: any) {
          return `${name}: ${errorData.errorCodes[name]}`;
        },
        selectedMode: false,
        top: 60,
        type: 'scroll',
      },
    });
  }

  extractErrorSeries();

  return <ReactECharts option={emptyOptions} ref={errorsChartRef} />;
}

export default ErrorAnalytics;
