import { baseTheme } from '../baseTheme';

const {
  palette,
  spacing,
  transitions: { create, duration, easing },
} = baseTheme;

export default {
  defaultProps: {
    variant: 'permanent',
  },
  styleOverrides: {
    root: {
      maxWidth: '16%',
      transition: create('width', {
        easing: easing.easeIn,
        duration: duration.shorter,
      }),
    },
    paper: {
      border: 'none',
      backgroundColor: palette.background.navbar,
      padding: spacing(0.5, 0),
      position: 'static',
      top: 'initial',
    },
  },
};
