import { baseTheme } from '../baseTheme';

const { spacing } = baseTheme;

export default {
  MuiTextField: {
    defaultProps: {
      size: 'small',
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        minWidth: spacing(19),
      },
    },
  },
};
