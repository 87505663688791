import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, queryWithConfig, ReqMethod } from 'src/helpers';
import { generatePath } from 'src/utils';
import {
  DEVICES_FIRMWARE_UPD_GATEWAY_API,
  DEVICES_FIRMWARE_UPD_LEAF_API,
  DEVICES_GET_CONNECT_STRENGTH_API,
  DEVICES_GET_GATEWAY_API,
  DEVICES_GET_ITEM_API,
  DEVICES_GET_LEAF_API,
  DEVICES_GET_LIST_API,
  DEVICES_SEND_COMMAND_API,
  DEVICES_SEND_GATEWAY_CONFIG_API,
  DEVICES_STORE_API_KEY,
  DEVICES_STORE_LIST_TAG,
} from './constants';
import {
  DeviceCommandParams,
  DeviceConnectData,
  DeviceFirmWareParams,
  DeviceItem,
  DeviceOverviewGridData,
  DevicesData,
  DeviceType,
} from './types';
import {
  getDeviceCommandReq,
  getDeviceGatewayConfigReq,
  isInvalidatingCommand,
  mapDeviceItem,
  mapDeviceOverviewGridRes,
  mapDevicesResponse,
} from './utils';
import { AppConfig } from '../config';

export const devicesApi = createApi({
  reducerPath: DEVICES_STORE_API_KEY,
  baseQuery: baseQuery(),
  tagTypes: [DEVICES_STORE_LIST_TAG],
  endpoints: ({ mutation, query }) => ({
    getDevices: query<DevicesData, void>({
      query: () => ({
        url: DEVICES_GET_LIST_API,
        method: ReqMethod.Get,
        queryParams: { fetchContext: true },
      }),
      providesTags: [DEVICES_STORE_LIST_TAG],
      transformResponse: mapDevicesResponse,
    }),
    getDeviceItem: query<DeviceItem, string | undefined>({
      query: (id) => ({
        url: generatePath(DEVICES_GET_ITEM_API, { id }),
        method: ReqMethod.Get,
        queryParams: { fetchContext: true },
      }),
      providesTags: [DEVICES_STORE_LIST_TAG],
      transformResponse: mapDeviceItem,
    }),
    getDeviceGatewayLeafs: query<DeviceOverviewGridData, string | undefined>({
      query: (id) => ({
        url: generatePath(DEVICES_GET_GATEWAY_API, { id }),
        method: ReqMethod.Get,
        queryParams: { fetchContext: true, fetchLeafDevices: true },
      }),
      providesTags: [DEVICES_STORE_LIST_TAG],
      transformResponse: ({ leafDevices }, config: AppConfig) =>
        mapDeviceOverviewGridRes(leafDevices, config),
    }),
    getDeviceLeafGateways: query<DeviceOverviewGridData, string | undefined>({
      query: (id) => ({
        url: generatePath(DEVICES_GET_LEAF_API, { id }),
        method: ReqMethod.Get,
        queryParams: { fetchContext: true },
      }),
      providesTags: [DEVICES_STORE_LIST_TAG],
      transformResponse: ({ gateways }, config: AppConfig) =>
        mapDeviceOverviewGridRes(gateways, config),
    }),
    getDeviceConnectStrength: query<DeviceConnectData[], string | undefined>({
      query: (id) => ({
        url: generatePath(DEVICES_GET_CONNECT_STRENGTH_API, { id }),
        method: ReqMethod.Get,
      }),
    }),
    sendDeviceCommand: mutation<void, DeviceCommandParams>({
      query: (params) => ({
        url: generatePath(DEVICES_SEND_COMMAND_API, { id: params.id }),
        method: ReqMethod.Post,
        body: getDeviceCommandReq(params),
        queryParams: { isLeaf: params.type !== DeviceType.Gateway },
      }),
      invalidatesTags: isInvalidatingCommand,
    }),
    sendDeviceGatewayConfig: mutation<void, DeviceCommandParams>({
      queryFn: queryWithConfig<void, DeviceCommandParams>(
        ({ id, ...params }, config) => ({
          url: generatePath(DEVICES_SEND_GATEWAY_CONFIG_API, { id }),
          method: ReqMethod.Patch,
          body: getDeviceGatewayConfigReq(params, config),
        }),
      ),
      invalidatesTags: [DEVICES_STORE_LIST_TAG],
    }),
    updDeviceLeafFirmware: mutation<void, DeviceFirmWareParams>({
      query: ({ id, versionId }) => ({
        url: generatePath(DEVICES_FIRMWARE_UPD_LEAF_API, { id }),
        method: ReqMethod.Post,
        body: { blobFilePath: versionId },
      }),
      invalidatesTags: [DEVICES_STORE_LIST_TAG],
    }),
    updDeviceGatewayFirmware: mutation<void, DeviceFirmWareParams>({
      query: ({ id, versionId }) => ({
        url: generatePath(DEVICES_FIRMWARE_UPD_GATEWAY_API, { id }),
        method: ReqMethod.Put,
        body: `"${versionId}"`,
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: [DEVICES_STORE_LIST_TAG],
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useGetDeviceItemQuery,
  useGetDeviceGatewayLeafsQuery,
  useGetDeviceLeafGatewaysQuery,
  useGetDeviceConnectStrengthQuery,
  useSendDeviceCommandMutation,
  useSendDeviceGatewayConfigMutation,
  useUpdDeviceLeafFirmwareMutation,
  useUpdDeviceGatewayFirmwareMutation,
} = devicesApi;
