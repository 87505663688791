import { Link, Outlet } from 'react-router-dom';
import { Tab, Tabs, Grid, Tooltip } from '@mui/material';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import DeviceDetailsOverview from 'src/pages/DeviceDetails/DeviceDetailsOverview';
import LoadingIconButton from 'src/components/LoadingIconButton';
import { useMatchRoute } from './hooks';
import { RouteProps } from './types';
import { getNavRoutes } from './utils';

type Props<T> = {
  context?: T;
  routes: RouteProps[];
  refreshData: () => void;
};

const style = {
  mb: 2,
  borderBottom: 1,
  borderColor: 'divider',
};

function RoutableTabsLayout<T>({ context, routes, refreshData }: Props<T>) {
  const tabRoutes = getNavRoutes(routes);
  const currentRoute = useMatchRoute() as RouteProps;
  const index = tabRoutes.indexOf(currentRoute);
  const value = index < 0 ? false : index;

  return (
    <>
      <Tabs sx={style} value={value}>
        {tabRoutes.map(({ path = '', meta }, index) => (
          <Tab
            key={path}
            label={meta?.title}
            value={index}
            component={Link}
            to={path}
          />
        ))}
        <LoadingIconButton
          loading={false}
          onClick={() => refreshData()}
          style={{ marginLeft: 'auto' }}
        >
          <Tooltip title="Refresh">
            <CachedOutlinedIcon />
          </Tooltip>
        </LoadingIconButton>
      </Tabs>
      <Grid container spacing={2} rowGap={2}>
        <Grid item xs={9}>
          <Outlet context={context} />
        </Grid>
        <Grid item xs={3}>
          <DeviceDetailsOverview />
        </Grid>
      </Grid>
    </>
  );
}

export default RoutableTabsLayout;
