/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import utc from 'dayjs/plugin/utc';
import theme from 'src/theme';
import {
  Tooltip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  IconButton,
  Theme,
} from '@mui/material';
import { useState } from 'react';

dayjs.extend(utc);

type Params = {
  initialAfterDate: dayjs.Dayjs;
  initialBeforeDate: dayjs.Dayjs;
  setFilteringOptions: (
    afterDate: dayjs.Dayjs,
    beforeDate: dayjs.Dayjs,
    serialNumbers: string,
    statuses: string,
  ) => void;
};

function CommandsSearchBar({
  initialAfterDate,
  initialBeforeDate,
  setFilteringOptions,
}: Params) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [afterDate, setAfterDate] = useState(initialAfterDate);
  const [beforeDate, setBeforeDate] = useState(initialBeforeDate);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [requestedSerialNumbers, setRequestedSerialNumbers] = useState<
    string | undefined
  >();

  const handleStatusChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedStatuses(typeof value === 'string' ? value.split(',') : value);
  };

  const statuses = [
    'Scheduled',
    'Pending',
    'Processed',
    'Acknowledged',
    'NotAcknowledged',
  ];

  function getStyles(
    status: string,
    statuses: readonly string[],
    theme: Theme,
  ) {
    return {
      fontWeight:
        statuses.indexOf(status) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="Registered after [UTC]"
          value={afterDate}
          timezone="UTC"
          slotProps={{
            textField: {
              size: 'small',
              style: { marginLeft: '5px' },
            },
          }}
          minutesStep={1}
          onChange={(newValue) => {
            setAfterDate(dayjs(newValue));
          }}
        />
        <DateTimePicker
          label="Registered before [UTC]"
          value={beforeDate}
          timezone="UTC"
          slotProps={{
            textField: {
              size: 'small',
              style: { marginLeft: '5px', marginRight: '5px' },
            },
          }}
          onChange={(newValue) => {
            setBeforeDate(dayjs(newValue));
          }}
        />
      </LocalizationProvider>
      AND
      <Tooltip title="Comma separated values">
        <TextField
          label="Serial Numbers"
          placeholder="serialOne, serialTwo, ..."
          sx={{ width: '300px', margin: '0 5px' }}
          onChange={(e) => setRequestedSerialNumbers(e.target.value)}
        />
      </Tooltip>
      AND
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="status-label" sx={{ marginTop: '-7px' }}>
          Statuses
        </InputLabel>
        <Select
          labelId="status-label"
          size="small"
          multiple
          value={selectedStatuses}
          onChange={handleStatusChange}
          input={<OutlinedInput label="Statuses" />}
          MenuProps={MenuProps}
        >
          {statuses.map((status) => (
            <MenuItem
              key={status}
              value={status}
              style={getStyles(status, statuses, theme)}
            >
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
        <IconButton
          onClick={() =>
            setFilteringOptions(
              afterDate,
              beforeDate,
              requestedSerialNumbers ?? '',
              selectedStatuses.join(','),
            )
          }
        >
          <CheckOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default CommandsSearchBar;
