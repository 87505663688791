import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CONFIG_STORE_KEY } from './constants';
import { AppConfig } from './types';
import { configApi } from './api';

const reducers = {
  setConfig: (state: AppConfig, { payload }: PayloadAction<AppConfig>) => ({
    ...state,
    ...payload,
  }),
};

export const configSlice = createSlice({
  name: CONFIG_STORE_KEY,
  initialState: { contract: {}, blueprint: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      configApi.endpoints.getAppConfig.matchFulfilled,
      reducers.setConfig,
    );
  },
});
